import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CurrentLanguage } from "../components/MenuItems"
import SingleRaisedFloorQuery from "./SingleRaisedFloorQuery"
import SingleProductQuery from "./SingleProductQuery"

export const AllSiding = () => {
  const { AllSiding } = useStaticQuery(query)
  const productType = "raisedFloor"

  return AllSiding.nodes
    .map((item, index) => {
      // const id = item?.id ?? undefined
      // const path = item?.data ?? undefined
      // const airtable = item ?? undefined

      // return SingleRaisedFloorQuery(path)
      return SingleProductQuery(item)
      // return SingleProductQuery(path)
      // return <SingleProductQuery path={path} siding />
    })
    .filter(item => !item.hide)
    .sort(function (a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
}

const query = graphql`
  {
    AllSiding: allAirtable(filter: { table: { eq: "Siding" } }) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          Ontslog {
            data {
              nameMn: mn
              nameEn: en
              Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      placeholder: NONE
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
          Type: Siding_Type {
            data {
              nameMn: Name_mongol
              nameEn: Name_english
              textMn: Mongol_tailbar
              textEn: English_tailbar
              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 1000, layout: CONSTRAINED)
                  }
                }
              }
              Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      placeholder: NONE
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
          size: Siding_Size
          mdMn: Description_mongol {
            internal {
              content
            }
          }
          mdEn: Description_english {
            internal {
              content
            }
          }
          New
          ontsloh: Ontsloh_baraa_bolgoh
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  layout: CONSTRAINED
                  # placeholder: BLURRED
                )
              }
            }
          }
          country: Made_In {
            data {
              mn
              en
              Flag {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
          Brand {
            data {
              mn
              en
              Logo {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 200
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
          Color {
            data {
              hex: Hex_Color
              nameMn: mn
              nameEn: en
              genColor: General_color
            }
          }
          Hide
        }
        id
        table
      }
    }
  }
`
