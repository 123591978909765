import React from "react"
import wave from "../images/background-header.svg"
import waveDots from "../images/wave-dots.svg"

const BackgroundSvg = ({ dots }) => {
  return (
    <div
      className="absolute inset-x-0 z-0 min-h-screen"
      style={{
        backgroundImage: `url(${wave})`,
        backgroundSize: "170% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "20% 5%",
      }}
    >
      
      {!dots && (
        <div className="absolute right-0 flex-col items-center justify-center hidden min-h-screen md:flex">
          <img
            src={waveDots}
            alt="Wave Dots"
            className="h-56 py-2 text-gray-400 opacity-80"
          />
          <img
            src={waveDots}
            alt="Wave Dots"
            className="h-56 text-gray-400 opacity-80"
          />
        </div>
      )}
    </div>
  )
}

export default BackgroundSvg
