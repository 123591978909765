import React, { Children } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import { CurrentLanguage } from "../components/MenuItems"
import Title from "../components/Title"

export const TypeSiding = () => {
  const currentLanguage = CurrentLanguage()

  const { TypeSiding } = useStaticQuery(badgeQuery)
  const types = TypeSiding?.nodes?.map((item, index) => {
    const type = "siding"
    const path = item?.data ?? undefined
    const nameMn = path?.nameMn ?? undefined
    const nameEn = path?.nameEn ?? undefined
    const name = currentLanguage === "mn" ? nameMn : nameEn

    const url = nameEn ? nameEn.replace(/\s+/g, "-").toLowerCase() : "#"

    const textMn = path?.textMn ?? undefined
    const textEn = path?.textEn ?? undefined
    const sharp = getImage(path?.Image?.localFiles[0] ?? undefined)
    const image = sharp ? (
      <GatsbyImage image={sharp} alt={name} className="w-full" />
    ) : undefined

    return { name, url, image, type }
  })

  return types
}

export const Badges = () => {
  // const currentLanguage = CurrentLanguage()

  const { Badges } = useStaticQuery(badgeQuery)

  const allBadges = Badges?.nodes?.map((item, index) => {
    const name = item?.data?.Name ?? undefined
    const sharp = getImage(item?.data?.Icon?.localFiles[0])
    const icon = sharp ? <GatsbyImage image={sharp} alt={name} /> : undefined

    return { name, icon }
  })

  return allBadges
}

export const BadgeNew = () => {
  const { BadgeNew } = useStaticQuery(badgeQuery)

  return BadgeNew?.nodes?.map((item, index) => {
    const name = item?.data?.Name ?? undefined
    const sharp = getImage(item?.data?.Icon?.localFiles[0])
    const icon = sharp ? <GatsbyImage image={sharp} alt={name} /> : undefined

    return { name, icon }
  })
}

export const BadgeNew2 = () => {
  const { BadgeNew2 } = useStaticQuery(badgeQuery)

  return BadgeNew2?.nodes?.map((item, index) => {
    const name = item?.data?.Name ?? undefined
    const sharp = getImage(item?.data?.Icon?.localFiles[0])
    const icon = sharp ? <GatsbyImage image={sharp} alt={name} /> : undefined

    return { name, icon }
  })
}

export const BadgeHot = () => {
  const { BadgeHot } = useStaticQuery(badgeQuery)

  return BadgeHot?.nodes?.map((item, index) => {
    const name = item?.data?.Name ?? undefined
    const sharp = getImage(item?.data?.Icon?.localFiles[0])
    const icon = sharp ? <GatsbyImage image={sharp} alt={name} /> : undefined

    return { name, icon }
  })
}

export const BadgeBestseller = () => {
  const { BadgeBestseller } = useStaticQuery(badgeQuery)

  return BadgeBestseller?.nodes?.map((item, index) => {
    const name = item?.data?.Name ?? undefined
    const sharp = getImage(item?.data?.Icon?.localFiles[0])
    const icon = sharp ? (
      <GatsbyImage image={sharp} alt={name} className="w-full" />
    ) : undefined

    return { name, icon }
  })
}

export const BadgeSale = () => {
  const { BadgeSale } = useStaticQuery(badgeQuery)

  return BadgeSale?.nodes?.map((item, index) => {
    const name = item?.data?.Name ?? undefined
    const sharp = getImage(item?.data?.Icon?.localFiles[0])
    const icon = sharp ? <GatsbyImage image={sharp} alt={name} /> : undefined

    return { name, icon }
  })
}

export const HaveSaleWallpapers = () => {
  const { HaveSaleWallpapers } = useStaticQuery(badgeQuery)

  const saleArray = HaveSaleWallpapers?.nodes?.map(
    item => item?.data?.Sale ?? undefined
  )
  const filteredArray = saleArray.filter(Boolean)
  // return filteredArray.length ? true : false
  // const reducer = (acc, cur) => acc + cur
  // return filteredArray?.reduce(reducer) === 0 ? false : true
  return filteredArray.length === 0 ? false : true
  // return filteredArray === [] ? false : true
}

export const RoomFilter = () => {
  const currentLanguage = CurrentLanguage()

  const { RoomFilter } = useStaticQuery(badgeQuery)

  const allBadges = RoomFilter?.nodes?.map((item, index) => {
    const name = item?.data?.Name ?? undefined
    const sharp = getImage(item?.data?.Icon?.localFiles[0])
    const icon = sharp ? <GatsbyImage image={sharp} alt={name} /> : undefined

    return { name, icon }
  })

  return allBadges
}

export const WallpaperDesc = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"

  const { WallpaperDesc } = useStaticQuery(badgeQuery)

  const path = WallpaperDesc?.nodes ?? undefined
  return path
    ? path.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.nameMn ?? undefined
        const nameEn = path?.nameEn ?? undefined
        const name = mn ? nameMn : nameEn

        const mdMn = path?.mdMn?.internal?.content ?? undefined
        const mdEn = path?.mdEn?.internal?.content ?? undefined
        const mdPure = mn ? mdMn : mdEn
        const md = (
          <div className="prose-sm sm:prose">
            <ReactMarkdown children={mdPure} />
          </div>
        )

        const sharpPath = path?.Image ?? undefined
        const sharp = sharpPath ? getImage(sharpPath.localFiles[0]) : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        // const sharpMenuPath = path?.Menu_Image ?? undefined
        // const sharpMenu = sharpMenuPath
        //   ? getImage(sharpMenuPath.localFiles[0])
        //   : undefined
        // const imageMenu = sharpMenu ? (
        //   <GatsbyImage image={sharpMenu} alt={name} className="w-full" />
        // ) : undefined

        // const sharpSmallPath = path?.ImageSmall ?? undefined
        // const sharpSmall = sharpSmallPath
        //   ? getImage(sharpSmallPath.localFiles[0])
        //   : undefined
        // const imageSmall = sharpSmall ? (
        //   <GatsbyImage image={sharpSmall} alt={name} className="w-full" />
        // ) : undefined

        return { name, md, image }
      })
    : undefined
}

const badgeQuery = graphql`
  {
    WallpaperDesc: allAirtable(
      filter: { table: { eq: "Wallpaper Description" } }
    ) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          mdMn: Mongol_Tailbar {
            internal {
              content
            }
          }
          mdEn: English_Tailbar {
            internal {
              content
            }
          }
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
          Menu_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 100
                  width: 200
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
    HaveSaleWallpapers: allAirtable(filter: { table: { eq: "Wallpaper" } }) {
      nodes {
        data {
          Sale
        }
      }
    }
    Badges: allAirtable(filter: { table: { eq: "Badge Icons" } }) {
      nodes {
        data {
          Name
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 100
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    BadgeNew: allAirtable(
      filter: { table: { eq: "Badge Icons" }, data: { Name: { eq: "New" } } }
    ) {
      nodes {
        data {
          Name
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: NONE
                  width: 100
                )
              }
            }
          }
        }
      }
    }
    BadgeNew2: allAirtable(
      filter: {
        table: { eq: "Badge Icons" }
        data: { Name: { eq: "New menu" } }
      }
    ) {
      nodes {
        data {
          Name
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 100
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    BadgeHot: allAirtable(
      filter: { table: { eq: "Badge Icons" }, data: { Name: { eq: "Hot" } } }
    ) {
      nodes {
        data {
          Name
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 100
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    BadgeBestseller: allAirtable(
      filter: {
        table: { eq: "Badge Icons" }
        data: { Name: { eq: "Bestseller" } }
      }
    ) {
      nodes {
        data {
          Name
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 100
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    BadgeSale: allAirtable(
      filter: { table: { eq: "Badge Icons" }, data: { Name: { eq: "Sale" } } }
    ) {
      nodes {
        data {
          Name
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 100
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    RoomFilter: allAirtable(filter: { table: { eq: "Room" } }) {
      nodes {
        data {
          nameMn: mn
          nameEn: en
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }

    TypeSiding: allAirtable(filter: { table: { eq: "Siding Type" } }) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          textMn: Mongol_tailbar
          textEn: English_tailbar
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 100
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
  }
`
