import React, { Suspense, useContext, useEffect, useState } from "react"
import Footer from "./Footer"
import Sidebar from "./Sidebar"
import { GatsbyContext } from "../context/context"
import Header from "./header"
import MenuModal from "./MenuModal"
import ScrollArrow from "./ScrollArrow"
import bgHeaderSvg from "../images/background-header.svg"
import BackgroundSvg from "./BackgroundSvg"
import { Helmet } from "react-helmet"

const Layout = ({ children, dots, svgHide }) => {
  const { isSidebarOpen } = useContext(GatsbyContext)
  const { isMegaMenuOpen, showMega, hideMega, modalMenuOpen } = useContext(
    GatsbyContext
  )

  // const [contentLoading, setContentLoading] = useState(false)
  // const [scrollArrowShow, setScrollArrowShow] = useState(false)

  // useEffect(() => {
  //   setScrollArrowShow(false)
  //   if (!loading) {
  //     setScrollArrowShow(true)
  //   }
  // }, [loading])

  // console.log(loading)
  // console.log(scrollArrowShow)

  return (
    <>
      <Helmet>
        <html lang="en" />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#3c6a84" />
        <meta name="theme-color" content="#3c6a84"></meta>
      </Helmet>

      <div className="relative flex flex-col min-h-screen antialiased text-gray-800 border-t-4 border-brand border-opacity-70">
        <Header />
        {isSidebarOpen && <Sidebar />}
        {modalMenuOpen && <MenuModal />}
        <main className="flex-1 w-full mx-auto antialiased debug-screens">
          {!svgHide && <BackgroundSvg dots={dots} />}
          {children}
          {typeof window !== "undefined" && window && (
            <ScrollArrow />
            // <ScrollArrow scrollArrowShow={scrollArrowShow} />
          )}
          {/* {isMegaMenuOpen && (
          <div
            className={`absolute inset-0 z-20  bg-black bg-opacity-10 transition  ${
              isMegaMenuOpen ? "flex" : "hidden"
            }`}
          ></div>
          
          // <div
          //   className={`absolute inset-0 z-20  bg-black bg-opacity-30 transition backdrop-filter backdrop-blur-sm  ${
          //     isMegaMenuOpen ? "flex" : "hidden"
          //   }`}
          //   // className={`absolute inset-0 z-20  bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg transition ${
          //   //   isMegaMenuOpen ? "flex" : "hidden"
          //   // }`}
          // ></div>
        )} */}
        </main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
