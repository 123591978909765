import React from "react"
import { useTranslation } from "react-i18next"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { TextChangeLanguage } from "./MenuItems"

const ChangeLanguageSidebar = () => {
  const {  i18n } = useTranslation()
  const currentLanguage = i18n.language

  function handleClick(lang) {
    i18n.changeLanguage(lang)
  }

  const { flags } = useStaticQuery(query)
  const flagImages = flags.nodes.map((item, index) => {
    const image = getImage(item)
    const langName = item.name
    return (
      <button
        key={index}
        className={`flex items-stretch w-full h-12 px-8 mt-4  border-t hover:bg-brand-light group focus:outline-none ${
          langName === currentLanguage ? "hidden" : ""
        }`}
        onClick={() => handleClick(langName)}
      >
        <span className="flex items-center mr-4">
          <GatsbyImage image={image} alt={langName} className="w-6 h-6" />
        </span>
        <span className="flex items-center h-full text-xs uppercase text-brand-darker group-hover:text-brand-darkest">
          {TextChangeLanguage()}
        </span>
      </button>
    )
  })

  return <div>{flagImages}</div>
}

const query = graphql`
  {
    flags: allFile(filter: { name: { in: ["en", "mn"] } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 100, quality: 70)
        }
        name
      }
    }
  }
`

export default ChangeLanguageSidebar
