import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"
import { CurtainDesc } from "../graphql/CurtainSmallQueries"
import { WallpaperDescription } from "../graphql/WallpaperMenuQuery"
import { HuldaasDesc } from "../graphql/HuldaasSmallQueries"
import { HivsentserDesc } from "../graphql/HivsentserSmallQueries"
import { RaisedFloorDesc } from "../graphql/RaisedFloorSmallQueries"
import { Desc } from "../graphql/SidingSmallQueries"
import {
  WallHide,
  FloorHide,
  SidingHide,
  PtileHide,
  CarpetHide,
  CurtainHide,
} from "../graphql/ControlQuery"

export const CurrentLanguage = () => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  return currentLanguage
}

export const MenuProductItems = () => {
  const currentLanguage = CurrentLanguage()

  const {
    wallpaperMenuName,
    curtainMenuName,
    hivsentserMenuName,
    huldaasMenuName,
    raisedFloorMenuName,
    sidingMenuName,
  } = useStaticQuery(query)

  const menuProductItem = [
    wallpaperMenuName,
    curtainMenuName,
    hivsentserMenuName,
    huldaasMenuName,
    raisedFloorMenuName,
    sidingMenuName,
  ]

  const productMenuItems = menuProductItem.map(item => {
    const itemNameMn = item.nodes[0].data.nameMn.trim()
    const itemNameEn = item.nodes[0].data.nameEn.trim()
    const name = currentLanguage === "mn" ? itemNameMn : itemNameEn
    const itemLink = itemNameEn
      ? itemNameEn.replace(/\s+/g, "-").toLowerCase()
      : "#"
    const link = `/${itemLink}`
    return { name, link }
  })

  return productMenuItems
}

export const MenuProductItemsFooter = () => {
  const currentLanguage = CurrentLanguage()
  const curtainHide = CurtainHide()
  const wallHide = WallHide()
  const ptileHide = PtileHide()
  const carpetTileHide = CarpetHide()

  const {
    wallpaperMenuName,
    curtainMenuName,
    hivsentserMenuName,
    huldaasMenuName,
  } = useStaticQuery(query)

  const menuProductItem = [
    !wallHide && wallpaperMenuName,
    !curtainHide && curtainMenuName,
    !ptileHide && hivsentserMenuName,
    !carpetTileHide && huldaasMenuName,
  ].filter(Boolean)

  const productMenuItems = menuProductItem.map(item => {
    const itemNameMn = item.nodes[0].data.nameMn.trim()
    const itemNameEn = item.nodes[0].data.nameEn.trim()
    const name = currentLanguage === "mn" ? itemNameMn : itemNameEn
    const itemLink = itemNameEn.replace(/\s+/g, "-").toLowerCase()
    const link = `/${itemLink}`

    return { name, link }
  })

  return productMenuItems
}

export const MenuServiceItems = () => {
  const currentLanguage = CurrentLanguage()

  const { raisedFloorMenuName, sidingMenuName, ugsralt } = useStaticQuery(query)

  const menuServiceItems = [raisedFloorMenuName, sidingMenuName]

  const constructTextMn = ugsralt.nodes[0].data.textMn
  const constructTextEn = ugsralt.nodes[0].data.textEn
  const constructText =
    currentLanguage === "mn" ? constructTextMn : constructTextEn

  const serviceItems = menuServiceItems.map(item => {
    const itemNameMn = item.nodes[0].data.nameMn.trim()
    const itemNameEn = item.nodes[0].data.nameEn.trim()
    const name = `${
      currentLanguage === "mn" ? itemNameMn : itemNameEn
    } & ${constructText}`
    const itemLink = itemNameEn.replace(/\s+/g, "-").toLowerCase()
    const link = `/${itemLink}`
    return { name, link }
  })

  return serviceItems
}

export const MenuServiceItemsFooterConstruct = () => {
  const currentLanguage = CurrentLanguage()
  const raisedFloorHide = RaisedFloorDesc()[0].menuHide
  const sidingHide = Desc()[0].menuHide

  const { raisedFloorMenuName, sidingMenuName, ugsralt } = useStaticQuery(query)

  const menuServiceItems = [
    !raisedFloorHide && raisedFloorMenuName,
    !sidingHide && sidingMenuName,
  ].filter(Boolean)

  const constructTextMn = ugsralt.nodes[0].data.textMn
  const constructTextEn = ugsralt.nodes[0].data.textEn
  const constructText =
    currentLanguage === "mn" ? constructTextMn : constructTextEn

  const serviceItems = menuServiceItems.map(item => {
    const itemNameMn = item.nodes[0].data.nameMn.trim()
    const itemNameEn = item.nodes[0].data.nameEn.trim()
    const name = `${
      currentLanguage === "mn" ? itemNameMn : itemNameEn
    } & ${constructText}`
    const itemLink = itemNameEn.replace(/\s+/g, "-").toLowerCase()
    const link = `/${itemLink}`
    return { name, link }
  })

  return serviceItems
}

export const MenuServiceItemsFooter = () => {
  const currentLanguage = CurrentLanguage()

  const { raisedFloorMenuName, sidingMenuName } = useStaticQuery(query)

  const menuServiceItems = [raisedFloorMenuName, sidingMenuName]

  const serviceItems = menuServiceItems.map(item => {
    const itemNameMn = item.nodes[0].data.nameMn.trim()
    const itemNameEn = item.nodes[0].data.nameEn.trim()
    const name = `${currentLanguage === "mn" ? itemNameMn : itemNameEn}`
    const itemLink = itemNameEn.replace(/\s+/g, "-").toLowerCase()
    const link = `/${itemLink}`
    return { name, link }
  })

  return serviceItems
}

export const MenuServiceName = () => {
  const currentLanguage = CurrentLanguage()
  const { serviceMenuName } = useStaticQuery(query)

  const serviceMenuMn = serviceMenuName.nodes[0].data.nameMn
  const serviceMenuEn = serviceMenuName.nodes[0].data.nameEn
  const serviceMenuText =
    currentLanguage === "mn" ? serviceMenuMn : serviceMenuEn

  return serviceMenuText
}

export const MenuProductName = () => {
  const currentLanguage = CurrentLanguage()
  const { productMenuName } = useStaticQuery(query)

  const productMenuMn = productMenuName.nodes[0].data.nameMn
  const productMenuEn = productMenuName.nodes[0].data.nameEn
  const productMenuText =
    currentLanguage === "mn" ? productMenuMn : productMenuEn

  return productMenuText
}

export const TextHome = () => {
  const currentLanguage = CurrentLanguage()
  const { textHome } = useStaticQuery(query)

  const textMn = textHome.nodes[0].data.nameMn
  const textEn = textHome.nodes[0].data.nameEn
  const text = currentLanguage === "mn" ? textMn : textEn

  return text
}

export const TextRaisedFloor = () => {
  const currentLanguage = CurrentLanguage()
  const { textRaisedFloor } = useStaticQuery(query)

  const textMn = textRaisedFloor.nodes[0].data.nameMn
  const textEn = textRaisedFloor.nodes[0].data.nameEn
  const text = currentLanguage === "mn" ? textMn : textEn

  return text
}

export const TextSiding = () => {
  const currentLanguage = CurrentLanguage()
  const { TextSiding } = useStaticQuery(query)

  const textMn = TextSiding.nodes[0].data.nameMn
  const textEn = TextSiding.nodes[0].data.nameEn
  const text = currentLanguage === "mn" ? textMn : textEn

  return text
}

export const TextWorks = () => {
  const currentLanguage = CurrentLanguage()
  const { TextWorks } = useStaticQuery(query)

  const textMn = TextWorks.nodes[0].data.nameMn
  const textEn = TextWorks.nodes[0].data.nameEn
  const text = currentLanguage === "mn" ? textMn : textEn

  return text
}

export const TextContact = () => {
  const currentLanguage = CurrentLanguage()
  const { TextContact } = useStaticQuery(query)

  const textMn = TextContact?.nodes[0]?.data?.nameMn
  const textEn = TextContact?.nodes[0]?.data?.nameEn
  const text = currentLanguage === "mn" ? textMn : textEn

  return text
}

export const TextMainBranchAddress = () => {
  const currentLanguage = CurrentLanguage()
  const { TextMainBranchAddress } = useStaticQuery(query)

  const textMn = TextMainBranchAddress?.nodes[0]?.data?.nameMn ?? undefined
  const textEn = TextMainBranchAddress?.nodes[0]?.data?.nameEn ?? undefined
  const text = currentLanguage === "mn" ? textMn : textEn

  return text
}

export const TextMainBranchEmail = () => {
  const { TextMainBranchEmail } = useStaticQuery(query)

  const email = TextMainBranchEmail.nodes[0].data.Email

  return email
}

export const TextMainBranchPhone = () => {
  const { TextMainBranchPhone } = useStaticQuery(query)

  const phone = TextMainBranchPhone?.nodes[0]?.data?.Phone

  return phone
}

export const TextFacebookAddress = () => {
  const { TextFacebookAddress } = useStaticQuery(query)

  const facebook = TextFacebookAddress.nodes[0].data.Facebook

  return facebook
}

export const GoogleMapFullMainBranch = () => {
  const { GoogleMapFullMainBranch } = useStaticQuery(query)

  const googleMapsFullAddress = GoogleMapFullMainBranch.nodes[0].data.Google_map

  return googleMapsFullAddress
}

// export const GoogleMapFullSalbarBranch = () => {
//   const { GoogleMapFullSalbarBranch } = useStaticQuery(query)

//   const data = GoogleMapFullSalbarBranch.nodes[0].data.Google_map

//   return data
// }

export const WallpaperPageBigCaption = () => {
  const currentLanguage = CurrentLanguage()
  const { WallpaperPageBigCaption } = useStaticQuery(query)

  const textMn = WallpaperPageBigCaption.nodes[0].data.nameMn
  const textEn = WallpaperPageBigCaption.nodes[0].data.nameEn
  const text = currentLanguage === "mn" ? textMn : textEn

  return text
}

export const WallpaperPageTuslahText = () => {
  const currentLanguage = CurrentLanguage()
  const { WallpaperPageTuslahText } = useStaticQuery(query)

  const textMn = WallpaperPageTuslahText.nodes[0].data.nameMn
  const textEn = WallpaperPageTuslahText.nodes[0].data.nameEn
  const text = currentLanguage === "mn" ? textMn : textEn

  return text
}

export const TextChangeLanguage = () => {
  const currentLanguage = CurrentLanguage()
  const { TextChangeLanguage } = useStaticQuery(query)

  const textMn = TextChangeLanguage.nodes[0].data.nameMn
  const textEn = TextChangeLanguage.nodes[0].data.nameEn
  const text = currentLanguage === "mn" ? textEn : textMn

  return text
}

export const MenuHeadName = () => {
  const currentLanguage = CurrentLanguage()
  const { menuHeadName } = useStaticQuery(query)

  const menuHeadNameMn = menuHeadName.nodes[0].data.nameMn
  const menuHeadNameEn = menuHeadName.nodes[0].data.nameEn
  const menuHeadNameText =
    currentLanguage === "mn" ? menuHeadNameMn : menuHeadNameEn

  return menuHeadNameText
}

export const PictureMainBranchGadnaZurag = () => {
  const { PictureMainBranchGadnaZurag } = useStaticQuery(query)

  const image = getImage(
    PictureMainBranchGadnaZurag?.nodes[0]?.data?.image?.localFiles[0]
  )
  const gadnaZurag = <GatsbyImage image={image} alt="Төв дэлгүүр гадна зураг" />

  return gadnaZurag
}

export const PictureMainBranchDotorZurag = () => {
  const { PictureMainBranchDotorZurag } = useStaticQuery(query)

  const image = getImage(
    PictureMainBranchDotorZurag?.nodes[0]?.data?.image?.localFiles[0]
  )
  const gadnaZurag = image && (
    <GatsbyImage image={image} alt="Салбар дэлгүүр гадна зураг" />
  )

  return gadnaZurag
}

export const PictureMainBranchDotorZuragnuud = () => {
  const { PictureMainBranchDotorZurag } = useStaticQuery(query)

  const images = PictureMainBranchDotorZurag?.nodes[0]?.data?.image?.localFiles?.map(
    (item, index) => {
      const fluid = getImage(item)
      return (
        <div key={index}>
          <GatsbyImage image={fluid} alt="Images" />
        </div>
      )
    }
  )

  const loopedImages = images.map((item, index) => {
    return <div key={index}>{item}</div>
  })

  return loopedImages
}

export const PictureMainBranchDotorZuragnuudNotReturnDiv = () => {
  const { PictureMainBranchDotorZurag } = useStaticQuery(query)

  const images = PictureMainBranchDotorZurag?.nodes[0]?.data?.image?.localFiles?.map(
    (item, index) => {
      const fluid = getImage(item)
      return <GatsbyImage image={fluid} alt="Images" key={index} />
    }
  )

  return images
}

export const MenuAddress = () => {
  const currentLanguage = CurrentLanguage()
  const { address } = useStaticQuery(query)

  const addressEn = address.nodes[0].data.addEn
  const addressMn = address.nodes[0].data.addMn
  const storeNameEn = address.nodes[0].data.nameEn
  const storeNameMn = address.nodes[0].data.nameMn
  const addressText = currentLanguage === "mn" ? addressMn : addressEn
  const storeNameText = currentLanguage === "mn" ? storeNameMn : storeNameEn

  return { addressText, storeNameText }
}

const query = graphql`
  {
    wallpaperMenuName: allAirtable(
      filter: { table: { eq: "Wallpaper Description" } }
    ) {
      nodes {
        data {
          nameEn: Name_english
          nameMn: Name_mongol
        }
      }
    }
    raisedFloorMenuName: allAirtable(
      filter: { table: { eq: "RF Description" } }
    ) {
      nodes {
        data {
          nameEn: Name_english
          nameMn: Name_mongol
        }
      }
    }
    curtainMenuName: allAirtable(
      filter: { table: { eq: "Curtain Description" } }
    ) {
      nodes {
        data {
          nameEn: Name_english
          nameMn: Name_mongol
        }
      }
    }
    hivsentserMenuName: allAirtable(
      filter: { table: { eq: "Hivsentser Description" } }
    ) {
      nodes {
        data {
          nameEn: Name_english
          nameMn: Name_mongol
        }
      }
    }
    huldaasMenuName: allAirtable(
      filter: { table: { eq: "Huldaas Description" } }
    ) {
      nodes {
        data {
          nameEn: Name_english
          nameMn: Name_mongol
        }
      }
    }
    sidingMenuName: allAirtable(
      filter: { table: { eq: "Siding Description" } }
    ) {
      nodes {
        data {
          nameEn: Name_english
          nameMn: Name_mongol
        }
      }
    }

    productMenuName: allAirtable(
      filter: {
        table: { eq: "Texts" }
        data: {
          Bairshil__Medeelliig_uurchilj_bolohgui_: { eq: "Бүтээгдэхүүн" }
        }
      }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    TextPhoneName: allAirtable(
      filter: {
        table: { eq: "Texts" }
        data: { Bairshil__Medeelliig_uurchilj_bolohgui_: { eq: " Утас" } }
      }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    serviceMenuName: allAirtable(
      filter: {
        table: { eq: "Texts" }
        data: { Bairshil__Medeelliig_uurchilj_bolohgui_: { eq: "Үйлчилгээ" } }
      }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    menuHeadName: allAirtable(
      filter: {
        table: { eq: "Texts" }
        data: {
          Bairshil__Medeelliig_uurchilj_bolohgui_: { eq: "Product and Service" }
        }
      }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    ugsralt: allAirtable(
      filter: {
        table: { eq: "Texts" }
        data: { Bairshil__Medeelliig_uurchilj_bolohgui_: { eq: "Угсралт" } }
      }
    ) {
      nodes {
        data {
          textMn: Mongol_text
          textEn: English_text
        }
      }
    }
    booltHamgaalagch: allAirtable(
      filter: { table: { eq: "Boolt Sulrahaas Hamgaalagch" } }
    ) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
        }
      }
    }
    noseMask: allAirtable(filter: { table: { eq: "Nose Mask" } }) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
        }
      }
    }
    eggRollic: allAirtable(filter: { table: { eq: "Egg Rollic" } }) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
        }
      }
    }
    address: allAirtable(filter: { table: { eq: "Contact" } }) {
      nodes {
        data {
          addEn: Address_english
          addMn: Address_mongol
          nameEn: Name_english
          nameMn: Name_mongol
        }
      }
    }
    textHome: allAirtable(
      filter: {
        table: { eq: "Texts" }
        data: { Bairshil__Medeelliig_uurchilj_bolohgui_: { eq: "Нүүр хуудас" } }
      }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    textContact: allAirtable(
      filter: {
        table: { eq: "Texts" }
        data: {
          Bairshil__Medeelliig_uurchilj_bolohgui_: { eq: "Холбоо барих" }
        }
      }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    TextWorks: allAirtable(
      filter: {
        table: { eq: "Texts" }
        data: {
          Bairshil__Medeelliig_uurchilj_bolohgui_: { eq: "Гүйцэтгэсэн ажлууд" }
        }
      }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    textRaisedFloor: allAirtable(filter: { table: { eq: "RF Description" } }) {
      nodes {
        data {
          nameEn: Name_english
          nameMn: Name_mongol
        }
      }
    }
    TextSiding: allAirtable(filter: { table: { eq: "Siding Description" } }) {
      nodes {
        data {
          nameEn: Name_english
          nameMn: Name_mongol
        }
      }
    }

    TextContact: allAirtable(
      filter: {
        table: { eq: "Texts" }
        data: {
          Bairshil__Medeelliig_uurchilj_bolohgui_: { eq: "Холбоо барих" }
        }
      }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    TextChangeLanguage: allAirtable(
      filter: {
        table: { eq: "Texts" }
        data: { Bairshil__Medeelliig_uurchilj_bolohgui_: { eq: "Хэл солих" } }
      }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    WallpaperPageBigCaption: allAirtable(
      filter: {
        table: { eq: "Wallpaper page text" }
        data: { Tailbar: { eq: "Том гарчиг" } }
      }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    WallpaperPageTuslahText: allAirtable(
      filter: {
        table: { eq: "Wallpaper page text" }
        data: { Tailbar: { eq: "Туслах текст" } }
      }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    # TextMainBranchName: allAirtable(
    #   filter: { table: { eq: "Contact" }, data: { Branch: { eq: "1" } } }
    # ) {
    #   nodes {
    #     data {
    #       nameMn: Name_mongol
    #       nameEn: Name_english
    #     }
    #   }
    # }

    TextMainBranchAddress: allAirtable(
      filter: { table: { eq: "Contact" }, data: { Branch: { eq: "1" } } }
    ) {
      nodes {
        data {
          nameMn: Address_mongol
          nameEn: Address_english
        }
      }
    }
    TextMainBranchEmail: allAirtable(
      filter: { table: { eq: "Contact" }, data: { Branch: { eq: "1" } } }
    ) {
      nodes {
        data {
          Email
        }
      }
    }
    TextMainBranchPhone: allAirtable(
      filter: { table: { eq: "Contact" }, data: { Branch: { eq: "1" } } }
    ) {
      nodes {
        data {
          Phone
        }
      }
    }

    TextFacebookAddress: allAirtable(
      filter: { table: { eq: "Contact" }, data: { Branch: { eq: "1" } } }
    ) {
      nodes {
        data {
          Facebook
        }
      }
    }

    PictureMainBranchGadnaZurag: allAirtable(
      filter: { table: { eq: "Contact" }, data: { Branch: { eq: "1" } } }
    ) {
      nodes {
        data {
          image: Outside_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 800)
              }
            }
          }
        }
      }
    }
    PictureMainBranchDotorZurag: allAirtable(
      filter: { table: { eq: "Contact" }, data: { Branch: { eq: "1" } } }
    ) {
      nodes {
        data {
          image: Inside_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }
  }
`

// export default ProductItems
