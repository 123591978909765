import React, { useContext, useState } from "react"
import { Link } from "gatsby"
// import logo from "../images/nstandard_logo.svg"
import logo from "../images/Long.png"
// import logo from "../images/ns14.svg" // last huvilbar bsan
// import logo from "../images/ns3.svg"
// import logo from "../images/logo-uppercase.png"
import { GatsbyContext } from "../context/context"
import ProductDropdownMenu from "./ProductDropdownMenu"
import { MenuAlt1Icon } from "@heroicons/react/solid"
import NewSmallLanguageSwitcher from "./NewSmallLanguageSwitcher"
import SecondNewSmallLanguageSwitcher from "./SecondNewSmallLanguageSwitcher"
import OldHeaderMenu from "./OldHeaderMenu"
import NewHeaderMenu from "./NewHeaderMenu"
import ProductAndServiceMegaMenu from "./ProductAndServiceMegaMenu"
import MenuModal from "./MenuModal"
import { FaPhoneAlt } from "react-icons/fa"
import { LanguageChangerHide } from "../graphql/ControlQuery"

const Header = () => {
  const [isExpanded, toggleExpansion] = useState(false)
  const {
    isSidebarOpen,
    showSidebar,
    isMegaMenuOpen,
    showMega,
    hideMega,
    modalMenuOpen,
    showModalMenu,
    hideModalMenu,
    toggleModalMenu,
  } = useContext(GatsbyContext)

  const [productMenuOpen, setProductMenuOpen] = useState(false)

  // Түр нэмлээ 20210526
  // const [modalMenuOpen, setModalMenuOpen] = useState(false)
  // function handleClick() {
  //   setModalMenuOpen(!modalMenuOpen)
  // }

  return (
    <header className="sticky top-0 z-50 antialiased bg-opacity-80 sm:relative backdrop-filter backdrop-blur-sm">
      <div className="relative flex flex-wrap items-stretch justify-between max-w-screen-lg px-4 mx-auto">
        <Link
          to="/"
          className="relative flex items-center py-4 outline-none focus:outline-none sm:py-2"
        >
          {/* JapanStandard */}
          <img
            src={logo}
            alt="Nipponstandard logo"
            // className="relative outline-none h-9 sm:h-10 focus:outline-none" // my last bsan
            className="relative h-6 outline-none lg:h-7 focus:outline-none"
          />
          <div className="absolute inset-0 w-full h-full">&nbsp;</div>
        </Link>

        {/* Түр зуур идэвхигүй болгоё */}
        {/* {!isSidebarOpen && (
          <button
            className="relative flex items-center justify-end flex-1 outline-none md:hidden active::outline-none"
            onClick={showSidebar}
          >
            <MenuAlt1Icon className="relative flex items-center outline-none text-brand-darker w-7 h-7 active:outline-none hover:outline-none" />
          </button>
        )} */}

        {/* Түр зуур нэмлээ 20210326 */}
        {!modalMenuOpen && (
          <button
            className="relative flex items-center justify-end flex-1 outline-none md:hidden active::outline-none focus:outline-none"
            onClick={() => toggleModalMenu()}
          >
            <MenuAlt1Icon className="relative flex items-center w-12 h-12 py-2 text-gray-800 outline-none active:outline-none hover:outline-none" />
          </button>
        )}

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:flex w-full md:w-auto justify-end flex-1 relative space-x-2`}
        >
          <NewHeaderMenu />

          {!LanguageChangerHide() && (
            <span className="flex items-center justify-center">
              <SecondNewSmallLanguageSwitcher small />
            </span>
          )}
        </nav>
        <ProductAndServiceMegaMenu />
      </div>
    </header>
  )
}

export default Header
