import React, { useContext, useState, useEffect } from "react"
import { AllOtherProducts, MenuImage } from "../graphql/OtherSmallQueries"
import { GatsbyContext } from "../context/context"
import { Link } from "gatsby"
import { WallpaperDescription } from "../graphql/WallpaperMenuQuery"
import { RaisedFloorDesc } from "../graphql/RaisedFloorSmallQueries"
import { Desc } from "../graphql/SidingSmallQueries"
import { HivsentserDesc } from "../graphql/HivsentserSmallQueries"
import { HuldaasDesc } from "../graphql/HuldaasSmallQueries"
import { CurtainDesc } from "../graphql/CurtainSmallQueries"
import { AllWallpapers } from "../graphql/WallpaperPageFullQuery"
import { AllRaisedFloor } from "../graphql/RaisedFloorQuery"
import { AllSiding } from "../graphql/AllSidingQuery"
import { AllHivsentser } from "../graphql/AllHivsentserQuery"
import { AllHuldaas } from "../graphql/AllHuldaasQuery"
import { AllCurtain } from "../graphql/AllCurtainQuery"
// import soWhite from "../images/so-white.png"
import {
  WallHide,
  FloorHide,
  SidingHide,
  PtileHide,
  CarpetHide,
  CurtainHide,
} from "../graphql/ControlQuery"
import { OthersText } from "../graphql/QueryById"

const TopSubMenu = ({ setMenuLength }) => {
  const { isMegaMenuOpen, showMega, hideMega } = useContext(GatsbyContext)
  const [value, setValue] = useState()

  const wallCount = AllWallpapers().length
  const floorCount = AllRaisedFloor().length
  const sidingCount = AllSiding().length
  const hivsentserCount = AllHivsentser().length
  const huldaasCount = AllHuldaas().length
  const curtainCount = AllCurtain().length
  const otherCount = AllOtherProducts().length
  const otherHide = otherCount === 0

  // console.log(floorCount)

  const headerMenu = [
    {
      name: WallpaperDescription().name,
      // icon: <GiRolledCloth />,
      image: WallpaperDescription().imageMenu,
      url: "/wallpaper",
      count: wallCount,
      menuHide: WallHide(),
      // component: <WallpaperMenu />,
    },
    {
      name: RaisedFloorDesc()[0].name,
      // icon: <ImStack />,
      image: RaisedFloorDesc()[0].imageMenu,
      url: "/raised-floor",
      count: floorCount,
      menuHide: FloorHide(),
      // component: <RaisedFloorMenu />,
    },
    {
      name: Desc()[0].name,
      // icon: <GiBrickWall />,
      image: Desc()[0].imageMenu,
      url: "/siding",
      count: sidingCount,
      menuHide: SidingHide(),
      // component: <SidingMenu />,
    },
    {
      name: HivsentserDesc()[0].name,
      // icon: <FaChessBoard />,
      image: HivsentserDesc()[0].imageMenu,
      url: "/carpet-tile",
      count: hivsentserCount,
      menuHide: CarpetHide(),
      // component: <HivsentserMenu />,
    },
    {
      name: HuldaasDesc()[0].name,
      // icon: <GiHeatHaze />,
      image: HuldaasDesc()[0].imageMenu,
      url: "/p-tile",
      count: huldaasCount,
      menuHide: PtileHide(),
      // component: <HuldaasMenu />,
    },
    {
      name: CurtainDesc()[0].name,
      // icon: <GiTheaterCurtains />,
      image: CurtainDesc()[0].imageMenu,
      url: "/curtain-rail",
      count: curtainCount,
      menuHide: CurtainHide(),
      // component: <CurtainMenu />,
    },
    {
      name: OthersText(),
      // icon: <GiAbstract077 />,
      image: MenuImage(),
      count: otherCount,
      menuHide: otherHide,
      url: "/other",
      // component: <OtherMenu />,
    },
  ]

  // console.log(MenuImage())
  // console.log(CurtainDesc())

  const headMenus = headerMenu
    .map((item, index) => {
      return (
        item.count &&
        !item.menuHide && (
          <Link
            to={`${item.url}`}
            key={index}
            className="relative flex items-center justify-center w-full h-full py-4 text-sm text-gray-800 bg-white border-gray-800 shadow-sm cursor-pointer group hover:border hover:text-black lg:text-base hover:shadow-sm"
            onClick={hideMega}
          >
            {item.name}
          </Link>
        )
      )
    })
    .filter(Boolean)

  const menuLength = headMenus.length
  // console.log(menuLength)
  // useEffect(() => {
  //   setMenuLength(menuLength)
  // }, [headMenus])

  // console.log(headMenus.length)

  return (
    <>
      {menuLength === 7 && (
        <div className="z-50 grid items-center h-full grid-cols-4 grid-rows-2 gap-2 bg-gray-100 ">
          <div className="h-full row-span-2">{headMenus[0]}</div>
          <div className="h-full">{headMenus[1]}</div>
          <div className="h-full">{headMenus[2]}</div>
          <div className="h-full">{headMenus[3]}</div>
          <div className="h-full">{headMenus[4]}</div>
          <div className="h-full">{headMenus[5]}</div>
          <div className="h-full">{headMenus[6]}</div>
        </div>
      )}
      {menuLength === 6 && (
        <div className="z-50 grid items-center h-full grid-cols-4 grid-rows-2 gap-2 bg-gray-100">
          <div className="h-full row-span-2">{headMenus[0]}</div>
          <div className="h-full col-span-2">{headMenus[1]}</div>
          <div className="h-full">{headMenus[2]}</div>
          <div className="h-full">{headMenus[3]}</div>
          <div className="h-full">{headMenus[4]}</div>
          <div className="h-full">{headMenus[5]}</div>
        </div>
      )}
      {menuLength === 5 && (
        <div className="z-50 grid items-center h-full grid-cols-5 gap-2 bg-gray-100">
          <div className="h-full">{headMenus[0]}</div>
          <div className="h-full">{headMenus[1]}</div>
          <div className="h-full">{headMenus[2]}</div>
          <div className="h-full">{headMenus[3]}</div>
          <div className="h-full">{headMenus[4]}</div>
        </div>
      )}
      {menuLength === 4 && (
        <div className="z-50 grid items-center h-full grid-cols-4 gap-2 bg-gray-100">
          <div className="h-full">{headMenus[0]}</div>
          <div className="h-full">{headMenus[1]}</div>
          <div className="h-full">{headMenus[2]}</div>
          <div className="h-full">{headMenus[3]}</div>
        </div>
      )}
      {menuLength === 3 && (
        <div className="z-50 grid items-center h-full grid-cols-3 gap-2 bg-gray-100">
          <div className="h-full">{headMenus[0]}</div>
          <div className="h-full">{headMenus[1]}</div>
          <div className="h-full">{headMenus[2]}</div>
        </div>
      )}
      {menuLength === 2 && (
        <div className="z-50 grid items-center h-full grid-cols-2 gap-2 bg-gray-100">
          <div className="h-full">{headMenus[0]}</div>
          <div className="h-full">{headMenus[1]}</div>
        </div>
      )}
      {menuLength === 1 && (
        <div className="z-50 grid items-center h-full grid-cols-1 bg-gray-100">
          <div className="h-full">{headMenus[0]}</div>
        </div>
      )}
    </>
  )
}

export default TopSubMenu
