import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { CurrentLanguage } from "../components/MenuItems"
import ReactMarkdown from "react-markdown"

export const TypeMenu = () => {
  const currentLanguage = CurrentLanguage()
  const type = "wallpaper"

  const { TypeMenu } = useStaticQuery(query)

  const nameMn = TypeMenu?.nodes[0]?.data?.nameMn ?? ""
  const nameEn = TypeMenu?.nodes[0]?.data?.nameEn ?? ""
  const name = currentLanguage === "mn" ? nameMn : nameEn

  // const slug = nameEn ? nameEn.replace(/\s+/g, "-").toLowerCase() : "#"
  const slug = "wallpaper-angilal-category"

  const fluid = getImage(
    TypeMenu?.nodes[0]?.data?.image?.localFiles[0] ?? undefined
  )
  const image = fluid ? (
    <GatsbyImage image={fluid} alt={name} className="w-full" />
  ) : undefined

  return { name, image, slug, type }
}

export const ColorMenuQuery = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const type = "wallpaper"

  const { ColorMenuQuery } = useStaticQuery(query)
  const path = ColorMenuQuery?.nodes[0] ?? undefined

  const nameMn = path ? path.data?.nameMn : undefined
  const nameEn = path ? path.data?.nameEn : undefined
  const name = mn ? nameMn : nameEn

  // const slug = nameEn ? nameEn.replace(/\s+/g, "-").toLowerCase() : "#"
  const slug = "wallpaper-color-category"

  const sharp = path ? getImage(path.data?.image?.localFiles[0]) : undefined

  const image = sharp ? (
    <GatsbyImage image={sharp} alt={name} className="w-full" />
  ) : undefined

  return { name, image, slug, type }
}

export const WallpaperDescription = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const categoryUrl = "wallpaper"

  const { WallpaperDescription } = useStaticQuery(query)
  const mainPath = WallpaperDescription?.nodes
    ? WallpaperDescription?.nodes[0]
    : undefined
  const path = mainPath?.data ?? undefined

  const nameMn = path?.nameMn ?? undefined
  const nameEn = path?.nameEn ?? undefined
  const name = mn ? nameMn : nameEn

  const slug = nameEn ? nameEn.replace(/\s+/g, "-").toLowerCase() : undefined

  const mdMn = path?.mdMn ? path.mdMn.internal?.content : undefined
  const mdEn = path?.mdEn ? path.mdEn.internal?.content : undefined
  const mdRaw = mn ? mdMn : mdEn
  const md = (
    <div className="px-4 prose-sm sm:prose">
      <ReactMarkdown children={mdRaw} />
    </div>
  )

  const sharpPath = path?.Image ?? undefined
  const sharp = sharpPath ? getImage(sharpPath.localFiles[0]) : undefined
  const image = sharp ? (
    <GatsbyImage image={sharp} alt={name} className="w-full" />
  ) : undefined

  const sharpMenuPath = path?.Menu_Image ?? undefined
  const sharpMenu = sharpMenuPath
    ? getImage(sharpMenuPath.localFiles[0])
    : undefined
  const imageMenu = sharpMenu ? (
    <GatsbyImage image={sharpMenu} alt={name} className="w-full" />
  ) : undefined

  const tovchMn = path?.tovchMn ?? undefined
  const tovchEn = path?.tovchEn ?? undefined
  const tovch = mn ? tovchMn : tovchEn

  return { name, image, slug, categoryUrl, md, imageMenu, tovch }
}

export const RoomMenu = () => {
  const currentLanguage = CurrentLanguage()
  const type = "wallpaper"

  const { RoomMenu } = useStaticQuery(query)

  const nameMn = RoomMenu?.nodes[0]?.data?.nameMn ?? ""
  const nameEn = RoomMenu?.nodes[0]?.data?.nameEn ?? ""
  const name = currentLanguage === "mn" ? nameMn : nameEn

  // const slug = nameEn ? nameEn.replace(/\s+/g, "-").toLowerCase() : "#"
  const slug = "room-category"

  const fluid = getImage(
    RoomMenu?.nodes[0]?.data?.image?.localFiles[0] ?? undefined
  )
  const image = fluid ? (
    <GatsbyImage image={fluid} alt={name} className="w-full" />
  ) : undefined

  return { name, image, slug, type }
}

export const LuxuryMenuQuery = () => {
  const currentLanguage = CurrentLanguage()
  const type = "wallpaper"

  const { LuxuryMenuQuery } = useStaticQuery(query)

  const nameMn = LuxuryMenuQuery?.nodes[0]?.data?.nameMn ?? ""
  const nameEn = LuxuryMenuQuery?.nodes[0]?.data?.nameEn ?? ""
  const name = currentLanguage === "mn" ? nameMn : nameEn

  // const slug = nameEn ? nameEn.replace(/\s+/g, "-").toLowerCase() : "#"
  const slug = "luxury-category"

  const fluid = getImage(
    LuxuryMenuQuery?.nodes[0]?.data?.image?.localFiles[0] ?? undefined
  )
  const image = fluid ? (
    <GatsbyImage image={fluid} alt={name} className="w-full" />
  ) : undefined

  return { name, image, slug, type }
}

export const SaleMenuQuery = () => {
  const currentLanguage = CurrentLanguage()
  const type = "wallpaper"

  const { SaleMenuQuery } = useStaticQuery(query)

  const nameMn = SaleMenuQuery?.nodes[0]?.data?.nameMn ?? ""
  const nameEn = SaleMenuQuery?.nodes[0]?.data?.nameEn ?? ""
  const name = currentLanguage === "mn" ? nameMn : nameEn

  // const slug = nameEn ? nameEn.replace(/\s+/g, "-").toLowerCase() : "#"
  const slug = "sale"

  const fluid = getImage(
    SaleMenuQuery?.nodes[0]?.data?.image?.localFiles[0] ?? undefined
  )
  const image = fluid ? (
    <GatsbyImage image={fluid} alt={name} className="w-full" />
  ) : undefined

  return { name, image, slug, type }
}

const query = graphql`
  {
    TypeMenu: allAirtable(
      filter: {
        table: { eq: "Wallpaper Menus" }
        data: { Table_name__uurchilj_bolohgui_: { eq: "Wallpaper Angilal" } }
      }
    ) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          image: Menu_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }
    ColorMenuQuery: allAirtable(
      filter: {
        table: { eq: "Wallpaper Menus" }
        data: { Table_name__uurchilj_bolohgui_: { eq: "Wallpaper Color" } }
      }
    ) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          image: Menu_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }
    RoomMenu: allAirtable(
      filter: {
        table: { eq: "Wallpaper Menus" }
        data: { Table_name__uurchilj_bolohgui_: { eq: "Room" } }
      }
    ) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          image: Menu_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }
    LuxuryMenuQuery: allAirtable(
      filter: {
        table: { eq: "Wallpaper Menus" }
        data: { Table_name__uurchilj_bolohgui_: { eq: "Luxury" } }
      }
    ) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          image: Menu_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }
    SaleMenuQuery: allAirtable(
      filter: {
        table: { eq: "Wallpaper Menus" }
        data: { Table_name__uurchilj_bolohgui_: { eq: "Sale" } }
      }
    ) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          image: Menu_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }
    WallpaperDescription: allAirtable(
      filter: { table: { eq: "Wallpaper Description" } }
    ) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          mdMn: Mongol_Tailbar {
            internal {
              content
            }
          }
          mdEn: English_Tailbar {
            internal {
              content
            }
          }
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(width: 1200, layout: CONSTRAINED, placeholder: NONE)
              }
            }
          }
          Menu_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 100
                  width: 200
                  layout: CONSTRAINED
                )
              }
            }
          }
          tovchMn: Tovch_tailbar_mongol
          tovchEn: Tovch_tailbar_english
        }
      }
    }
  }
`
