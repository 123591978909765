import React, { useEffect, useState } from "react"
import { FaArrowCircleUp } from "react-icons/fa"
// import "../styles/scroll.css"

const ScrollArrow = () => {
  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  window.addEventListener("scroll", checkScrollTop)

  return (
    // scrollArrowShow && (
    <FaArrowCircleUp
      // className="scrollTop"
      className={`z-50 w-10 h-10 text-brand transition-opacity bottom-16 right-5 opacity-30 hover:opacity-100 hidden fixed  ${
        showScroll ? "sm:flex" : "hidden"
      }`}
      onClick={scrollTop}
      // style={{ height: 40, display: showScroll ? "hidden sm:flex" : "none" }}
    />
    // )
  )
}

export default ScrollArrow
