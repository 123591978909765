import React from "react"
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaFacebook,
  // FaInstagram,
} from "react-icons/fa"
import { AllContact } from "../graphql/ContactQuery"
import { HiOutlineMailOpen } from "react-icons/hi"
// import { FacebookHide, InstagramHide } from "../graphql/ControlQuery"
import { FacebookHide } from "../graphql/ControlQuery"

const ButtomSocialMenu = ({ menuItemsCount }) => {
  const mainBranch = AllContact().filter(item => item.branch === "1")[0]
  const facebook = mainBranch.facebook
  // const instagram = mainBranch.instagram
  const phone = mainBranch.phone
  const email = mainBranch.email

  return (
    <div
      className={`flex items-center col-span-2  justify-evenly max-w-screen-md sm:mx-auto mx-2 opacity-70 ${
        menuItemsCount === 5 ? "mt-4 mb-0" : "py-1"
      }`}
    >
      {phone && (
        <a href={`tel:${phone}`} className="flex p-4">
          <FaPhoneAlt className="w-10 h-10 p-2 text-gray-100 rounded-full bg-brand md:h-12 md:w-12" />
        </a>
      )}
      {email && (
        <a href={`mailto:${email}`} target="_blank" className="flex p-4">
          <HiOutlineMailOpen className="w-10 h-10 text-brand md:h-12 md:w-12" />
        </a>
      )}
      {facebook && !FacebookHide() && (
        <a href={facebook} target="_blank" className="flex p-4">
          <FaFacebook className="w-10 h-10 text-brand md:h-12 md:w-12" />
        </a>
      )}
      {/* {instagram && !InstagramHide() && (
        <a href={instagram} target="_blank" className="flex p-4">
          <FaInstagram className="w-10 h-10 text-brand md:h-12 md:w-12" />
        </a>
      )} */}
    </div>
  )
}

export default ButtomSocialMenu
