import React, { useContext } from "react"
import { Link } from "gatsby"
import { GatsbyContext } from "../context/context"
import {
  LightningBoltIcon,
  PuzzleIcon,
  OfficeBuildingIcon,
  HomeIcon,
  XIcon,
  HandIcon,
  PhoneIcon,
  DeviceMobileIcon,
} from "@heroicons/react/solid"
import ChangeLanguageSidebar from "./ChangeLanguageSidebar"
import {
  TextHome,
  MenuProductName,
  MenuServiceName,
  TextRaisedFloor,
  TextSiding,
  TextWorks,
  TextContact,
  MenuProductItems,
} from "./MenuItems"
import { FaTools } from "react-icons/fa"

const Sidebar = () => {
  const { modelMenuOpen, showModalMenu, hideModalMenu } = useContext(
    GatsbyContext
  )
  const { hideSidebar } = useContext(GatsbyContext)

  return (
    <div className="fixed inset-0 z-50 flex items-start justify-center pt-10 bg-black bg-opacity-60 md:hidden">
      {/* <div className="relative w-9/12 max-w-sm py-10 overflow-auto bg-gray-50 md:max-w-xl"> */}
      <div className="absolute inset-0 w-full h-full">&nbsp;</div>
      <div className="absolute inset-0 w-full h-full py-10 overflow-auto text-sm bg-brand-lighter md:max-w-xl bg-opacity-90">
        <button
          onClick={hideSidebar}
          className="absolute z-50 text-3xl bg-transparent border-transparent top-4 right-4"
        >
          <XIcon className="w-8 h-8 text-brand-darker hover:text-brand-darkest " />
        </button>
        <div className="grid gap-x-2 gap-y-2">
          <div className="divide-y divide-gray-200">
            <div className="pt-2 mt-2">
              <Link
                to="/"
                onClick={hideSidebar}
                className="grid items-center gap-3 px-8 py-2 uppercase text-brand-darker grid-cols-auto1fr group-hover:text-brand-darkest group hover:bg-gray-100"
              >
                <HomeIcon className="flex items-center w-5 h-5 text-brand group-hover:text-brand-darker" />
                {TextHome()}
              </Link>
            </div>

            <div className="pt-2 mt-2">
              {MenuProductItems().map((item, index) => {
                const { link, name, icon } = item
                return (
                  <Link
                    to={link}
                    key={index}
                    onClick={hideSidebar}
                    className="grid items-center gap-3 px-8 py-2 uppercase text-brand-darker grid-cols-auto1fr group-hover:text-brand-darkest group hover:bg-gray-100"
                  >
                    <LightningBoltIcon className="flex items-center w-5 h-5 text-brand group-hover:text-brand-darker" />
                    {name}
                  </Link>
                )
              })}
            </div>
            <div className="pt-2 mt-2">
              <Link
                to="/projects"
                onClick={hideSidebar}
                className="grid items-center gap-3 px-8 py-2 uppercase text-brand-darker grid-cols-auto1fr group-hover:text-brand-darkest group hover:bg-gray-100"
              >
                <FaTools className="flex items-center w-5 h-5 text-brand group-hover:text-brand-darker" />
                {/* <HandIcon className="flex items-center w-5 h-5 text-brand group-hover:text-brand-darker" /> */}
                {TextWorks()}
              </Link>
              <Link
                to="/contact"
                onClick={hideSidebar}
                className="grid items-center gap-3 px-8 py-2 uppercase text-brand-darker grid-cols-auto1fr group-hover:text-brand-darkest group hover:bg-gray-100"
              >
                <DeviceMobileIcon className="flex items-center w-5 h-5 text-brand group-hover:text-brand-darker" />
                {TextContact()}
              </Link>
            </div>
          </div>

          <div className="mt-2">
            <ChangeLanguageSidebar />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
