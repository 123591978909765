import React, { Children } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import { CurrentLanguage } from "../components/MenuItems"
import Title from "../components/Title"

export const AllRaisedFloor = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const { AllRaisedFloor } = useStaticQuery(query)
  const productType = "raisedFloor"

  const data = AllRaisedFloor.nodes.map((item, index) => {
    const path = item?.data ?? undefined

    const nameMn = path?.nameMn?.trim() ?? undefined
    const nameEn = path?.nameEn?.trim() ?? undefined
    const name = mn ? nameMn : nameEn

    const slug = nameEn ? nameEn.replace(/\s+/g, "-").toLowerCase() : "#"

    const type = path?.Type?.map((subItem, index) => {
      const pathSub = subItem?.data ?? undefined
      const nameTypeMn = pathSub?.nameTypeMn?.trim() ?? undefined
      const nameTypeEn = pathSub?.nameTypeEn?.trim() ?? undefined
      const nameType = mn ? nameTypeMn : nameTypeEn

      const textTypeMn = pathSub?.textTypeMn?.trim() ?? undefined
      const textTypeEn = pathSub?.textTypeEn?.trim() ?? undefined
      const textType = mn ? textTypeMn : textTypeEn

      const sharpType = getImage(pathSub?.Image?.localFiles[0])
      const imageType = sharpType ? (
        <GatsbyImage image={sharpType} alt={textType} className="w-full" />
      ) : undefined

      const sharpTypeIcon = getImage(pathSub?.Icon?.localFiles[0])
      const iconType = sharpTypeIcon ? (
        <GatsbyImage
          image={sharpTypeIcon}
          alt={`${textType} icon`}
          className="w-full"
        />
      ) : undefined

      return { nameType, textType, imageType, iconType  }
    })

    const mdMn = path?.descMn?.internal?.content ?? undefined
    const mdEn = path?.descEn?.internal?.content ?? undefined
    const descBeforeMarkdown = mn ? mdMn : mdEn
    const descMd = descBeforeMarkdown ? (
      <ReactMarkdown children={descBeforeMarkdown} />
    ) : undefined

    const mainImages = path?.mainImages?.localFiles?.map((item, index) => {
      const sharp = getImage(item)
      const image = sharp ? (
        <GatsbyImage
          image={sharp}
          alt={`${name}-${index + 1}`}
          className="w-full"
        />
      ) : undefined
      return image
    })

    const brandPath = path?.brand[0]?.data ?? undefined
    const brandNameMn = brandPath ? brandPath.mn : undefined
    const brandNameEn = brandPath ? brandPath.en : undefined
    const brandName = mn ? brandNameMn : brandNameEn

    const brandLogoSharp = brandPath
      ? getImage(brandPath.Logo?.localFiles[0])
      : undefined
    const brandLogo = brandLogoSharp ? (
      <GatsbyImage image={brandLogoSharp} alt={brandName} className="w-full" />
    ) : undefined

    const madeInPath = path?.madeIn[0]?.data ?? undefined
    const madeInCountryMn = madeInPath ? madeInPath.mn : undefined
    const madeInCountryEn = madeInPath ? madeInPath.en : undefined
    const madeInCountry = mn ? madeInCountryMn : madeInCountryEn

    const countrySharp = madeInPath
      ? getImage(madeInPath.Flag?.localFiles[0])
      : undefined
    const madeInCountryFlag = countrySharp ? (
      <GatsbyImage
        image={countrySharp}
        alt={madeInCountry}
        className="w-full"
      />
    ) : undefined

    return {
      name,
      type,
      descMd,
      mainImages,
      brandName,
      brandLogo,
      madeInCountry,
      madeInCountryFlag,
      productType,
      slug,
    }
  })

  return data
}

const query = graphql`
  {
    AllRaisedFloor: allAirtable(filter: { table: { eq: "Raised Floor" } }) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          Type {
            data {
              nameTypeMn: Name_mongol
              nameTypeEn: Name_english
              textTypeMn: Mongol_tailbar
              textTypeEn: English_tailbar
              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 400
                      layout: CONSTRAINED
                    )
                  }
                }
              }
              Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 50
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
          descMn: Description_mongol {
            internal {
              content
            }
          }
          descEn: Description_english {
            internal {
              content
            }
          }
          mainImages: Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  layout: CONSTRAINED
                )
              }
            }
          }
          brand: Brand {
            data {
              mn
              en
              Logo {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 200, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          madeIn: Made_In {
            data {
              mn
              en
              Flag {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
