import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import { CurrentLanguage } from "../components/MenuItems"
import Title from "../components/Title"
import { useTranslation } from "react-i18next"

export const HuldaasBestFor = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const { HuldaasBestFor } = useStaticQuery(query)

  const path = HuldaasBestFor?.nodes ?? undefined
  return path
    ? path.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.nameMn ?? undefined
        const nameEn = path?.nameEn ?? undefined
        const name = mn ? nameMn : nameEn

        const sharpPath = path?.Icon ?? undefined
        const sharp = sharpPath ? getImage(sharpPath.localFiles[0]) : undefined
        const icon = sharp ? (
          <GatsbyImage
            image={sharp}
            alt={`${name}'s icon`}
            className="w-full"
          />
        ) : undefined

        const huldaasPath = path?.Huldaas ?? undefined
        const huldaasnuud = huldaasPath?.map(item => item.id)

        return { name, icon, huldaasnuud }
      })
    : undefined
}

export const HuldaasNiitlegShinj = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const { HuldaasNiitlegShinj } = useStaticQuery(query)

  const path = HuldaasNiitlegShinj?.nodes ?? undefined
  return path
    ? path.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.nameMn ?? undefined
        const nameEn = path?.nameEn ?? undefined
        const name = mn ? nameMn : nameEn

        const sharpPath = path?.Icon ?? undefined
        const sharp = sharpPath ? getImage(sharpPath.localFiles[0]) : undefined
        const icon = sharp ? (
          <GatsbyImage
            image={sharp}
            alt={`${name}'s icon`}
            className="w-full"
          />
        ) : undefined

        return { name, icon }
      })
    : undefined
}

export const HuldaasDesc = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const { HuldaasDesc } = useStaticQuery(query)
  const categoryUrl = "p-tile"

  const path = HuldaasDesc?.nodes ?? undefined
  const desc = path
    ? path.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.nameMn ?? undefined
        const nameEn = path?.nameEn ?? undefined
        const name = mn ? nameMn : nameEn

        const slug = nameEn
          ? nameEn.replace(/\s+/g, "-").toLowerCase()
          : undefined

        const mdMn = path?.mdMn?.internal?.content ?? undefined
        const mdEn = path?.mdEn?.internal?.content ?? undefined
        const mdRaw = mn ? mdMn : mdEn
        const md = (
          <div className="px-4 prose-sm sm:prose">
            <ReactMarkdown children={mdRaw} />
          </div>
        )

        const sharppath = path?.Image ?? undefined
        const sharp = sharppath ? getImage(sharppath.localFiles[0]) : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        const sharpMenuPath = path?.Menu_Image ?? undefined
        const sharpMenu = sharpMenuPath
          ? getImage(sharpMenuPath.localFiles[0])
          : undefined
        const imageMenu = sharpMenu ? (
          <GatsbyImage image={sharpMenu} alt={name} className="w-full" />
        ) : undefined

        const tovchMn = path?.tovchMn ?? undefined
        const tovchEn = path?.tovchEn ?? undefined
        const tovch = mn ? tovchMn : tovchEn

        return { name, md, image, categoryUrl, imageMenu, tovch, slug }
      })
    : undefined

  return desc
}

export const MenuImageOtherProducts = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const { MenuImageOtherProducts } = useStaticQuery(query)
  const productType = "curtain"

  const path = MenuImageOtherProducts?.nodes
    ? MenuImageOtherProducts.nodes[0]
    : undefined
  const imagePath = path?.Image ?? undefined
  const sharp = imagePath ? getImage(imagePath.localFiles[0]) : undefined
  const menuImage = sharp ? (
    <GatsbyImage
      image={sharp}
      alt="Other products menu image"
      className="w-full"
    />
  ) : undefined
  return menuImage
}

const query = graphql`
  {
    MenuImageOtherProducts: allAirtable(
      filter: {
        table: { eq: "Other images" }
        id: { eq: "136184ce-1f0e-51c2-83b9-14e7b78cec69" }
      }
    ) {
      nodes {
        data {
          Name
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  quality: 100
                  width: 200
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
    HuldaasBestFor: allAirtable(filter: { table: { eq: "Huldaas Best For" } }) {
      nodes {
        data {
          nameMn: mn
          nameEn: en
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
          Huldaas {
            id
          }
        }
      }
    }
    HuldaasNiitlegShinj: allAirtable(
      filter: { table: { eq: "Huldaas Niitleg shinj" } }
    ) {
      nodes {
        data {
          nameMn: mn
          nameEn: en
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    HuldaasDesc: allAirtable(filter: { table: { eq: "Huldaas Description" } }) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          mdMn: Mongol_Tailbar {
            internal {
              content
            }
          }
          mdEn: English_Tailbar {
            internal {
              content
            }
          }
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
            }
          }
          Menu_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 100
                  width: 200
                  layout: CONSTRAINED
                )
              }
            }
          }
          tovchMn: Tovch_tailbar_mongol
          tovchEn: Tovch_tailbar_english
        }
      }
    }
  }
`
