import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CurrentLanguage } from "../components/MenuItems"
import SingleRaisedFloorQuery from "./SingleRaisedFloorQuery"
import SingleProductQuery from "./SingleProductQuery"
import SingleHuldaasQuery from "./SingleHuldaasQuery"
import { useTranslation } from "react-i18next"
import { HuldaasDesc } from "../graphql/HuldaasSmallQueries"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

export const AllHuldaas = () => {
  const { AllHuldaas } = useStaticQuery(query)
  // const productType = "huldaas"

  return AllHuldaas.nodes
    .map((item, index) => {
      return SingleHuldaasQuery(item)
      // ############################ START ########################
      // ############################ START ########################
      // ############################ START ########################
      // const table = item?.table ?? undefined
      // // const isSiding = table === "Siding"
      // const path = item?.data ?? undefined

      // const { i18n } = useTranslation()
      // const currentLanguage = i18n.language
      // const mn = currentLanguage === "mn"
      // const productType = HuldaasDesc()[0]?.name?.trim()
      // const productCategory = "tile"

      // const name = path?.Name?.trim() ?? undefined

      // const slug = name ? name.replace(/\s+/g, "-").toLowerCase() : undefined

      // const textMn = path?.textMn ?? undefined
      // const textEn = path?.textEn ?? undefined
      // const text = mn ? textMn : textEn

      // const size = path?.size ?? undefined
      // const ontslohBaraa = path?.ontsloh ?? undefined

      // const imagepath = path?.Image ?? undefined
      // const image = imagepath
      //   ? imagepath.localFiles?.map((item, index) => {
      //       const sharp = item ? getImage(item) : undefined
      //       return sharp ? (
      //         <GatsbyImage
      //           image={sharp}
      //           alt={`${name}-${index + 1}`}
      //           className="w-full"
      //         />
      //       ) : undefined
      //     })
      //   : undefined

      // const brandpath = path?.Brand ?? undefined
      // const brand = brandpath
      //   ? brandpath.map((item, index) => {
      //       const path = item?.data ?? undefined
      //       const nameMn = path?.mn ?? undefined
      //       const nameEn = path?.en ?? undefined
      //       const name = mn ? nameMn : nameEn

      //       const sharppath = path?.Logo ?? undefined
      //       const sharp = sharppath
      //         ? getImage(sharppath.localFiles[0])
      //         : undefined
      //       const logo = sharp ? (
      //         <GatsbyImage image={sharp} alt={name} className="" />
      //       ) : undefined

      //       return { name, logo }
      //     })
      //   : undefined

      // const countrypath = path?.country ?? undefined
      // const country = countrypath
      //   ? countrypath.map((item, index) => {
      //       const path = item?.data ?? undefined
      //       const nameMn = path?.mn ?? undefined
      //       const nameEn = path?.en ?? undefined
      //       const name = mn ? nameMn : nameEn
      //       const sharppath = path?.Flag ?? undefined
      //       const sharp = sharppath
      //         ? getImage(sharppath.localFiles[0])
      //         : undefined
      //       const flag = sharp ? (
      //         <GatsbyImage image={sharp} alt={name} className="w-full" />
      //       ) : undefined

      //       return { name, flag }
      //     })
      //   : undefined

      // const colorPath = path?.Color ?? undefined
      // const color = colorPath
      //   ? colorPath.map((item, index) => {
      //       const path = item?.data ?? undefined
      //       const hex = path?.hex ?? undefined
      //       const nameMn = path?.nameMn ?? undefined
      //       const nameEn = path?.nameEn ?? undefined
      //       const name = mn ? nameMn : nameEn
      //       const baraan = path?.baraan ?? undefined

      //       return { hex, name, baraan }
      //     })
      //   : undefined

      // return {
      //   productCategory,
      //   table,
      //   productType,
      //   name,
      //   slug,
      //   text,
      //   size,
      //   ontslohBaraa,
      //   image,
      //   brand,
      //   country,
      //   color,
      // }

      // ############################ END ########################
      // ############################ END ########################
      // ############################ END ########################
    })
    .filter(item => !item.hide)
    .sort(function (a, b) {
      var nameA = a.name?.toUpperCase()
      var nameB = b.name?.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }

      return 0
    })
}

const query = graphql`
  {
    AllHuldaas: allAirtable(filter: { table: { eq: "Huldaas" } }) {
      nodes {
        data {
          Name
          size: Huldaas_Size
          textMn: Mongol_tailbar
          textEn: English_tailbar
          ontsloh: Ontsloh_baraa_bolgoh
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  layout: CONSTRAINED
                  # placeholder: BLURRED
                )
              }
            }
          }
          country: Made_In {
            data {
              mn
              en
              Flag {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
          Brand {
            data {
              mn
              en
              Logo {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 200
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
          Color {
            data {
              hex: Hex_Color
              nameMn: mn
              nameEn: en
              baraan: Baraanduu_ungutei
            }
          }
          Hide
        }
        id
        table
      }
    }
  }
`
