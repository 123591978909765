import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { GatsbyContext } from "../context/context"
import languageImageMn from "../images/mn.png"
import languageImageEn from "../images/en.png"
import { ChangeLanguageText } from "../graphql/QueryById"

const SecondNewSmallLanguageSwitcher = ({ showText, small }) => {
  // const { i18n } = useContext(GatsbyContext)
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language

  function handleClick(lang) {
    i18n.changeLanguage(lang)
    // console.log("language changing", currentLanguage)
  }

  return (
    <div className="flex h-full">
      <div
        className={`focus:outline-none outline-none flex items-center space-x-2 py-2 px-2 ${
          currentLanguage === "mn" ? "hidden" : ""
        }`}
        onClick={() => handleClick("mn")}
      >
        <img
          src={languageImageMn}
          alt="MongolFlag"
          className={` ${small ? "w-6 h-6" : "w-8 h-8"}`}
        />
        {showText && (
          <span className="items-center text-xs font-semibold text-gray-600 uppercase">
            {ChangeLanguageText()}
          </span>
        )}
      </div>
      <button
        className={`focus:outline-none outline-none flex items-center space-x-2 py-2 px-2  ${
          currentLanguage === "en" ? "hidden" : ""
        }`}
        onClick={() => handleClick("en")}
      >
        <img
          src={languageImageEn}
          alt="EnglishFlag"
          className={` ${small ? "w-6 h-6" : "w-8 h-8"}`}
        />
        {showText && (
          <span className="items-center text-xs font-semibold text-gray-600 uppercase ">
            {ChangeLanguageText()}
          </span>
        )}
      </button>
    </div>
  )
}

export default SecondNewSmallLanguageSwitcher
