import { Link } from "gatsby"
import React, { useContext } from "react"
// import { FaFacebook, FaInstagram } from "react-icons/fa"
import { FaFacebook } from "react-icons/fa"
import { GatsbyContext } from "../context/context"
import {
  MenuProductItemsFooter,
  MenuServiceItemsFooterConstruct,

  TextMainBranchAddress,
} from "./MenuItems"
import {
  OthersText,
  PhoneText,
  ProductText,
  CopyrightText,
  ContactText,
  AddressText,
  UgsraltText,
  ServiceText,
  BlogText,
} from "../graphql/QueryById"
import wave from "../images/background-header.svg"
import { useTranslation } from "react-i18next"

import { AllOtherProducts } from "../graphql/OtherSmallQueries"
import { AllContact } from "../graphql/ContactQuery"
import SecondNewSmallLanguageSwitcher from "./SecondNewSmallLanguageSwitcher"
// import { FacebookHide, InstagramHide } from "../graphql/ControlQuery"
import { FacebookHide } from "../graphql/ControlQuery"

const Footer = () => {
  const { isSidebarOpen } = useContext(GatsbyContext)
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const mn = currentLanguage === "mn"

  const mainBranch = AllContact().filter(item => item.branch === "1")[0]
  const facebook = mainBranch.facebook
  // const instagram = mainBranch.instagram
  const phone = mainBranch.phone

  const productColumn = MenuProductItemsFooter().map((item, index) => {
    return (
      <Link
        key={index}
        to={item.link}
        className="block py-2 duration-700 hover:text-gray-700"
      >
        {item.name}
      </Link>
    )
  })

  const serviceConsructColumn = MenuServiceItemsFooterConstruct().map(
    (item, index) => {
      return (
        <Link
          key={index}
          to={item.link}
          className="block py-2 duration-700 hover:text-gray-700"
        >
          {item.name}
        </Link>
      )
    }
  )

  const otherColumn = AllOtherProducts().map((item, index) => {
    return (
      <Link
        key={index}
        to="/other"
        className="block py-2 duration-700 hover:text-gray-700"
      >
        {item.name}
      </Link>
    )
  })

  return (
    <footer
      className={`z-10 pt-40  px-10 bg-auto antialiased  ${
        isSidebarOpen ? "opacity-30" : ""
      }`}
      style={{
        backgroundImage: `url(${wave})`,
        backgroundSize: "300% 130%",
        backgroundPosition: "20% top",
      }}
    >
      <div className="max-w-screen-lg mx-auto space-y-10 text-xs text-gray-500 divide-y divide-gray-300 lg:text-sm">
        <div className="grid grid-cols-2 gap-3 md:grid-cols-5">
          <div className="flex flex-col space-y-5">
            <div className="flex-auto ">
              <span className="block mb-2 text-xs font-bold text-gray-600 uppercase">
                {/* {MenuProductName()} */}
                {ProductText()}
              </span>
              {productColumn}
            </div>
          </div>
          <div className="flex flex-col space-y-5 ">
            <div className="flex-auto ">
              <span className="block mb-2 text-xs font-bold text-gray-600 uppercase">
                {/* {MenuServiceName()} */}
                {ServiceText()}
              </span>
              {serviceConsructColumn}
            </div>
            {otherColumn.length !== 0 && (
              <div className="flex-auto md:hidden">
                <span className="inline-block mb-2 text-xs font-bold text-gray-600 uppercase">
                  {OthersText()}
                </span>
                {otherColumn}
              </div>
            )}
            {/* <div className="flex-auto hidden md:block">
              <Link
                to="/blog"
                className="inline-block mb-2 text-xs font-bold text-gray-600 uppercase"
              >
                {BlogText()}
              </Link>
            </div> */}
          </div>

          {otherColumn.length !== 0 && (
            <div className="flex-col hidden space-y-5 md:flex">
              <div className="flex-auto">
                <span className="block mb-2 text-xs font-bold text-gray-600 uppercase">
                  {OthersText()}
                </span>
                {otherColumn}
              </div>
            </div>
          )}
          <div className="flex flex-col col-span-2 space-y-5 md:row-span-2">
            <div className="flex-auto ">
              <span className="block mb-2 text-xs font-bold text-gray-600 uppercase">
                {/* {TextContact()} */}
                {/* {TextContact()} */}
                {ContactText()}
              </span>
              <div className="mt-2">
                <div className="flex">
                  <div className="flex-none w-16 font-semibold ">
                    {PhoneText()}:
                  </div>
                  <a href={`tel:${phone}`} className="flex-grow ">
                    {phone}
                  </a>
                </div>
                <div className="mt-2">
                  {/* <div className="flex-none w-16 font-semibold">
                    {AddressText()}:
                  </div> */}
                  <div className="flex-grow">
                    <div>
                      {TextMainBranchAddress()}
                    </div>
                
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Нэмэлт категориуд нэмэх бол ердөө доорх шиг нэмээд явна */}
          {/* <div className="flex flex-col space-y-5 bg-yellow-300">5</div>
          <div className="flex flex-col space-y-5 bg-green-300">6</div> */}
        </div>
        <div className="justify-between flex-1 sm:flex">
          <div className="flex items-center justify-center py-6 space-x-2 sm:justify-start">
            {mn ? (
              <span className="font-semibold">
                &copy; Nipponstyle - 2016-{new Date().getFullYear()}
              </span>
            ) : (
              <span className="font-semibold">&copy; Nipponstyle - Since 2016</span>
            )}
            <span className="hidden sm:flex">{CopyrightText()}</span>
          </div>
          {/* <div>
            <SecondNewSmallLanguageSwitcher small showText />
          </div> */}
          <div className="flex items-stretch justify-center mb-8 sm:justify-end sm:mb-0">
            {facebook && !FacebookHide() && (
              <a
                href={facebook}
                target="_blank"
                rel="norefferer"
                className="flex items-center justify-center px-2 group"
              >
                <span className="inline-block">
                  <FaFacebook className="w-5 h-5 group-hover:text-gray-900" />
                </span>
              </a>
            )}
            {/* {instagram && !InstagramHide() && (
              <a
                href={instagram}
                target="_blank"
                rel="norefferer"
                className="flex items-center justify-center px-5 group"
              >
                <span className="inline-block">
                  <FaInstagram className="w-5 h-5 group-hover:text-gray-900" />
                </span>
              </a>
            )} */}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
