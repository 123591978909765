import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import { useTranslation } from "react-i18next"
import { RaisedFloorDesc } from "../graphql/RaisedFloorSmallQueries"
import { Desc } from "../graphql/SidingSmallQueries"

export const SingleProductQuery = airtable => {
  // console.log(airtable)
  // console.log(RaisedFloorDesc()[0])
  // console.log(Desc()[0])
  // console.log(siding)
  const table = airtable?.table ?? undefined
  const isSiding = table === "Siding"
  const path = airtable?.data ?? undefined
  const id = airtable?.id ?? undefined

  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const mn = currentLanguage === "mn"
  const productType = isSiding ? "siding" : "raised-floor"

  const hide = path?.Hide?? undefined
  // const productType = isSiding
  //   ? Desc()[0].name.trim()
  //   : RaisedFloorDesc()[0].name.trim()

  const nameMn = path?.nameMn?.trim() ?? undefined
  const nameEn = path?.nameEn?.trim() ?? undefined

  const name = mn ? (nameMn ? nameMn : nameEn) : nameEn ? nameEn : nameMn

  const slug = nameEn
    ? nameEn.replace(/\s+/g, "-").toLowerCase()
    : nameMn.replace(/\s+/g, "-").toLowerCase()

  const ontslogPath = path?.Ontslog ?? undefined
  const ontslog = ontslogPath
    ? ontslogPath.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.nameMn?.trim() ?? undefined
        const nameEn = path?.nameEn?.trim() ?? undefined
        const name = mn ? nameMn : nameEn

        const sharppath = path?.Icon ?? undefined
        const sharp = sharppath ? getImage(sharppath.localFiles[0]) : undefined
        const icon = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        return { name, icon }
      })
    : undefined

  const typepath = path?.Type ?? undefined
  const type = typepath
    ? typepath.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.nameMn?.trim()?.trim() ?? undefined
        const nameEn = path?.nameEn?.trim()?.trim() ?? undefined
        const name = mn ? nameMn : nameEn

        const textMn = path?.textMn?.trim() ?? undefined
        const textEn = path?.textEn?.trim() ?? undefined
        const text = mn ? textMn : textEn

        const sharp = path?.Image
          ? getImage(path.Image.localFiles[0])
          : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        const sharpIcon = path?.Icon
          ? getImage(path.Icon.localFiles[0])
          : undefined
        const icon = sharpIcon ? (
          <GatsbyImage
            image={sharpIcon}
            alt={`${name} icon`}
            className="w-full"
          />
        ) : undefined

        return { name, text, image, icon }
      })
    : undefined

  const mdMn = path?.mdMn?.internal?.content ?? undefined
  const mdEn = path?.mdEn?.internal?.content ?? undefined
  const mdPure = mn ? mdMn : mdEn
  const md = mdPure ? (
    <div className="prose-sm sm:prose">
      <ReactMarkdown children={mdPure} />
    </div>
  ) : undefined

  const size = path?.size ?? undefined
  const height = path?.Height ?? undefined
  const achaalal = path?.Achaalal ?? undefined
  const newProduct = path?.New ?? undefined
  const ontslohBaraa = path?.ontsloh ?? undefined

  const imagepath = path?.Image ?? undefined
  const image = imagepath
    ? imagepath.localFiles?.map((item, index) => {
        const sharp = item ? getImage(item) : undefined
        return sharp ? (
          <GatsbyImage
            key={index}
            image={sharp}
            alt={`${name}-${index + 1}`}
            className="w-full"
          />
        ) : undefined
      })
    : undefined

  const brandpath = path?.Brand ?? undefined
  const brand = brandpath
    ? brandpath.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.mn ?? undefined
        const nameEn = path?.en ?? undefined
        const name = mn ? nameMn : nameEn

        const sharppath = path?.Logo ?? undefined
        const sharp = sharppath ? getImage(sharppath.localFiles[0]) : undefined
        const logo = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="" />
        ) : undefined

        return { name, logo }
      })
    : undefined

  const countrypath = path?.country ?? undefined
  const country = countrypath
    ? countrypath.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.mn ?? undefined
        const nameEn = path?.en ?? undefined
        const name = mn ? nameMn : nameEn
        const sharppath = path?.Flag ?? undefined
        const sharp = sharppath ? getImage(sharppath.localFiles[0]) : undefined
        const flag = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        return { name, flag }
      })
    : undefined

  const colorPath = path?.Color ?? undefined
  const color = colorPath
    ? colorPath.map((item, index) => {
        const path = item?.data ?? undefined
        const hex = path?.hex ?? undefined
        const nameMn = path?.nameMn ?? undefined
        const nameEn = path?.nameEn ?? undefined
        const name = mn ? nameMn : nameEn
        const genColor = path?.genColor ?? undefined

        return { hex, name, genColor }
      })
    : undefined

  return {
    id,
    table,
    productType,
    name,
    slug,
    ontslog,
    type,
    md,
    size,
    height,
    achaalal,
    newProduct,
    ontslohBaraa,
    image,
    brand,
    country,
    color,
    hide
  }
}

export default SingleProductQuery
