import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import { CurrentLanguage } from "../components/MenuItems"
import Title from "../components/Title"
import { useTranslation } from "react-i18next"

export const AllContact = () => {
  const mn = CurrentLanguage() === "mn"
  const { AllContact } = useStaticQuery(query)

  const path = AllContact?.nodes ?? undefined
  return path
    ? path.map((item, index) => {
        const id = item?.id ?? undefined
        const path = item?.data ?? undefined
        const branch = path?.Branch ?? undefined
        const nameMn = path?.nameMn ?? undefined
        const nameEn = path?.nameEn ?? undefined
        const name = mn ? nameMn : nameEn

        const addMn = path?.addMn ?? undefined
        const addEn = path?.addEn ?? undefined
        const address = mn ? addMn : addEn

        const outPath = path?.outImage ?? undefined
        const outsideImage = outPath
          ? outPath.localFiles.map((item, index) => {
              const sharp = getImage(item)
              return (
                <GatsbyImage
                  key={index}
                  image={sharp}
                  alt={`NipponStyle ${name} outside-${index + 1}`}
                  className="w-full"
                />
              )
            })
          : undefined

        const inPath = path?.inImage ?? undefined
        const insideImage = inPath
          ? inPath.localFiles.map((item, index) => {
              const sharp = getImage(item)
              return (
                <GatsbyImage
                  key={index}
                  image={sharp}
                  alt={`NipponStyle ${name} inside-${index + 1}`}
                  className="w-full"
                />
              )
            })
          : undefined

        const long = path?.Lon ?? undefined
        const lat = path?.Lat ?? undefined

        const email = path?.Email ?? undefined
        const phone = path?.Phone ?? undefined
        const facebook = path?.Facebook ?? undefined
        // const instagram = path?.Instagram ?? undefined

        const postMn = path?.postMn ?? undefined
        const postEn = path?.postEn ?? undefined
        const post = mn ? postMn : postEn

        return {
          id,
          branch,
          name,
          address,
          outsideImage,
          insideImage,
          long,
          lat,
          email,
          phone,
          facebook,
          // instagram,
          post,
        }
      })
    : undefined
}

const query = graphql`
  {
    AllContact: allAirtable(filter: { table: { eq: "Contact" } }) {
      nodes {
        data {
          Branch
          # nameMn: Name_mongol
          # nameEn: Name_english
          addMn: Address_mongol
          addEn: Address_english
          outImage: Outside_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  quality: 100
                )
              }
            }
          }
          inImage: Inside_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  quality: 100
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
          Lon
          Lat
          Email
          Phone
          Facebook
          # Instagram
          postMn: Post_address_mongol
          PostEn: Post_address_english
        }
        id
      }
    }
  }
`
