import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CurrentLanguage } from "../components/MenuItems"
import SingleRaisedFloorQuery from "./SingleRaisedFloorQuery"
import SingleProductQuery from "./SingleProductQuery"
import SingleHuldaasQuery from "./SingleHuldaasQuery"
import { useTranslation } from "react-i18next"
import { HuldaasDesc } from "../graphql/HuldaasSmallQueries"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import SingleCurtainQuery from "./SingleCurtainQuery"

export const AllCurtain = () => {
  const { AllCurtain } = useStaticQuery(query)

  return AllCurtain.nodes
    .map((item, index) => {
      return SingleCurtainQuery(item)
    })
    .filter(item => !item.hide)
    .sort(function (a, b) {
      var nameA = a.name?.toUpperCase()
      var nameB = b.name?.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }

      return 0
    })
}

const query = graphql`
  {
    AllCurtain: allAirtable(filter: { table: { eq: "Curtain" } }) {
      nodes {
        data {
          Name
          size: Curtain_Size
          textMn: Mongol_tailbar
          textEn: English_tailbar
          ontsloh: Ontsloh_baraa_bolgoh
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  layout: CONSTRAINED
                  # placeholder: BLURRED
                )
              }
            }
          }
          country: Made_In {
            data {
              mn
              en
              Flag {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
          Brand {
            data {
              mn
              en
              Logo {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 200
                      layout: CONSTRAINED
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
          Color {
            data {
              hex: Hex_Color
              nameMn: mn
              nameEn: en
              baraan: Baraanduu_ungutei
            }
          }
          Hide
        }
        id
        table
      }
    }
  }
`
