import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import { CurrentLanguage } from "../components/MenuItems"
import Title from "../components/Title"
import { useTranslation } from "react-i18next"
import { SidingHide } from "./ControlQuery"

export const Hemjee = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const { Hemjee } = useStaticQuery(query)

  const path = Hemjee?.nodes ? Hemjee?.nodes[0]?.data : undefined
  const nameMn = path?.nameMn?.trim() ?? undefined
  const nameEn = path?.nameEn?.trim() ?? undefined
  const name = mn ? nameMn : nameEn

  const unitMn = path?.unitMn?.trim() ?? undefined
  const unitEn = path?.unitEn?.trim() ?? undefined
  const unit = mn ? unitMn : unitEn

  const sharpPath = path?.Icon ?? undefined
  const sharp = sharpPath ? getImage(sharpPath.localFiles[0]) : undefined
  const icon = sharp ? (
    <GatsbyImage image={sharp} alt={`${name}'s icon`} className="w-full" />
  ) : undefined

  return { name, unit, icon }
}

export const NiitlegShinj = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const { NiitlegShinj } = useStaticQuery(query)

  const path = NiitlegShinj?.nodes ?? undefined
  return path
    ? path.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.nameMn ?? undefined
        const nameEn = path?.nameEn ?? undefined
        const name = mn ? nameMn : nameEn

        const sharpPath = path?.Icon ?? undefined
        const sharp = sharpPath ? getImage(sharpPath.localFiles[0]) : undefined
        const icon = sharp ? (
          <GatsbyImage
            image={sharp}
            alt={`${name}'s icon`}
            className="w-full"
          />
        ) : undefined

        return { name, icon }
      })
    : undefined
}

export const Desc = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const { Desc } = useStaticQuery(query)
  const categoryUrl = "siding"
  const menuHide = SidingHide()

  const path = Desc?.nodes ?? undefined
  const desc = path
    ? path.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.nameMn ?? undefined
        const nameEn = path?.nameEn ?? undefined
        const name = mn ? nameMn : nameEn

        const slug = nameEn
          ? nameEn.replace(/\s+/g, "-").toLowerCase()
          : undefined

        const mdMn = path?.mdMn?.internal?.content ?? undefined
        const mdEn = path?.mdEn?.internal?.content ?? undefined
        const mdRaw = mn ? mdMn : mdEn
        const md = (
          <div className="px-4 prose-sm sm:prose">
            <ReactMarkdown children={mdRaw} />
          </div>
        )

        const sharppath = path?.Image ?? undefined
        const sharp = sharppath ? getImage(sharppath.localFiles[0]) : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        const sharpMenuPath = path?.Menu_Image ?? undefined
        const sharpMenu = sharpMenuPath
          ? getImage(sharpMenuPath.localFiles[0])
          : undefined
        const imageMenu = sharpMenu ? (
          <GatsbyImage image={sharpMenu} alt={name} className="w-full" />
        ) : undefined

        const tovchMn = path?.tovchMn ?? undefined
        const tovchEn = path?.tovchEn ?? undefined
        const tovch = mn ? tovchMn : tovchEn

        return { name, md, image, imageMenu, slug, categoryUrl, tovch, menuHide }
        // return { name, md, image, imageMenu, slug, categoryUrl, tovch }
      })
    : undefined

  return desc
}

export const Type = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const { Type } = useStaticQuery(query)

  const path = Type?.nodes ?? undefined
  return path
    ? path.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.nameMn ?? undefined
        const nameEn = path?.nameEn ?? undefined
        const name = mn ? nameMn : nameEn
        const productType = "raised-floor"

        const slug = nameEn ? nameEn.replace(/\s+/g, "-").toLowerCase() : "#"

        const sharppath = path?.Image ?? undefined
        const sharp = sharppath ? getImage(sharppath.localFiles[0]) : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        const sharpPathIcon = path?.Icon ?? undefined
        const sharpIcon = sharpPathIcon
          ? getImage(sharpPathIcon.localFiles[0])
          : undefined
        const icon = sharpIcon ? (
          <GatsbyImage image={sharpIcon} alt={name} className="w-full" />
        ) : undefined

        const relFloorPath = path?.relatedFloorId ?? undefined
        const relFloorIds = relFloorPath
          ? relFloorPath.map((item, index) => {
              return item?.id ?? undefined
            })
          : undefined

        return { name, image, icon, relFloorIds, slug, productType }
      })
    : undefined
}

const query = graphql`
  {
    Hemjee: allAirtable(
      filter: { id: { eq: "1585dbf1-e917-5f9f-a849-7512c9a949a4" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_name
          nameEn: English_name
          unitMn: Mongol_unit_name
          unitEn: English_unit_name
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    NiitlegShinj: allAirtable(
      filter: { table: { eq: "Siding Niitleg shinj" } }
    ) {
      nodes {
        data {
          nameMn: mn
          nameEn: en
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    Desc: allAirtable(filter: { table: { eq: "Siding Description" } }) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          mdMn: Mongol_Tailbar {
            internal {
              content
            }
          }
          mdEn: English_Tailbar {
            internal {
              content
            }
          }
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
            }
          }
          Menu_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 100
                  width: 200
                  layout: CONSTRAINED
                )
              }
            }
          }
          tovchMn: Tovch_tailbar_mongol
          tovchEn: Tovch_tailbar_english
        }
      }
    }
    Type: allAirtable(filter: { table: { eq: "Siding Type" } }) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          textMn: Mongol_tailbar
          textEn: English_tailbar
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(width: 400, layout: CONSTRAINED)
              }
            }
          }
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
          relatedFloorId: Raised_Floor {
            id
          }
        }
      }
    }
  }
`
