import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import { CurrentLanguage } from "../components/MenuItems"
import Title from "../components/Title"
import { useTranslation } from "react-i18next"

export const CurtainNiitlegShinj = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const { CurtainNiitlegShinj } = useStaticQuery(query)

  const path = CurtainNiitlegShinj?.nodes ?? undefined
  return path
    ? path.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.nameMn ?? undefined
        const nameEn = path?.nameEn ?? undefined
        const name = mn ? nameMn : nameEn

        const sharpPath = path?.Icon ?? undefined
        const sharp = sharpPath ? getImage(sharpPath.localFiles[0]) : undefined
        const icon = sharp ? (
          <GatsbyImage
            image={sharp}
            alt={`${name}'s icon`}
            className="w-full"
          />
        ) : undefined

        return { name, icon }
      })
    : undefined
}

export const CurtainDesc = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const { CurtainDesc } = useStaticQuery(query)
  const categoryUrl = "curtain-rail"

  const path = CurtainDesc?.nodes ?? undefined
  const desc = path
    ? path.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.nameMn ?? undefined
        const nameEn = path?.nameEn ?? undefined
        const name = mn ? nameMn : nameEn
        const slug = nameEn
          ? nameEn.replace(/\s+/g, "-").toLowerCase()
          : undefined

        const mdMn = path?.mdMn?.internal?.content ?? undefined
        const mdEn = path?.mdEn?.internal?.content ?? undefined
        const mdRaw = mn ? mdMn : mdEn
        const md = (
          <div className="px-4 prose-sm sm:prose">
            <ReactMarkdown children={mdRaw} />
          </div>
        )

        const sharppath = path?.Image ?? undefined
        const sharp = sharppath ? getImage(sharppath.localFiles[0]) : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        const sharpMenuPath = path?.Menu_Image ?? undefined
        const sharpMenu = sharpMenuPath
          ? getImage(sharpMenuPath.localFiles[0])
          : undefined
        const imageMenu = sharpMenu ? (
          <GatsbyImage image={sharpMenu} alt={name} className="w-full" />
        ) : undefined

        const tovchMn = path?.tovchMn ?? undefined
        const tovchEn = path?.tovchEn ?? undefined
        const tovch = mn ? tovchMn : tovchEn

        return { name, md, image, categoryUrl, imageMenu, tovch, slug }
      })
    : undefined

  return desc
}

const query = graphql`
  {
    CurtainNiitlegShinj: allAirtable(
      filter: { table: { eq: "Curtain Niitleg shinj" } }
    ) {
      nodes {
        data {
          nameMn: mn
          nameEn: en
          Icon {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 100
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }
    CurtainDesc: allAirtable(filter: { table: { eq: "Curtain Description" } }) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          mdMn: Mongol_Tailbar {
            internal {
              content
            }
          }
          mdEn: English_Tailbar {
            internal {
              content
            }
          }
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  width: 1200
                  layout: CONSTRAINED
                  placeholder: NONE
                )
              }
            }
          }
          Menu_Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 100
                  width: 200
                  layout: CONSTRAINED
                )
              }
            }
          }
          tovchMn: Tovch_tailbar_mongol
          tovchEn: Tovch_tailbar_english
        }
      }
    }
  }
`
