import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import { CurrentLanguage } from "../components/MenuItems"
import Title from "../components/Title"
import { useTranslation } from "react-i18next" // 20210423
import { WallpaperDesc } from "../graphql/SmallQueries"

// Ene chuhal query

export const AllWallpapers = () => {
  // ingej hiivel mash olon query yyseed udaashruulj bn
  // console.log(WallpaperDesc()[0].name)

  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  // console.log(currentLanguage)
  // const currentLanguage = CurrentLanguage()
  // const mn = currentLanguage === "mn"
  const { AllWallpapers } = useStaticQuery(query)
  const path = AllWallpapers?.nodes ?? undefined

  return path
    ? path
        .map((item, index) => {
          const itemPath = item?.data ?? undefined
          const name = itemPath?.Name ?? undefined
          const slug = name ? name.replace(/\s+/g, "-").toLowerCase() : "#"
          const productType = "wallpaper"
          const menuHide = itemPath?.menuHide ?? undefined

          const id = item?.id ?? undefined

          // ############ TYPE ################
          const typePath = itemPath?.Type ?? undefined
          const angilal = typePath
            ? typePath.map((item, index) => {
                const typePath = item?.data ?? undefined
                const nameMn = typePath?.nameMn?.trim() ?? undefined
                const nameEn = typePath?.nameEn?.trim() ?? undefined
                const name = currentLanguage === "mn" ? nameMn : nameEn

                const slug = nameEn
                  ? nameEn.replace(/\s+/g, "-").toLowerCase()
                  : "#"

                const textMn = typePath?.textMn?.trim() ?? undefined
                const textEn = typePath?.textEn?.trim() ?? undefined
                const text = currentLanguage === "mn" ? textMn : textEn

                const imagepath = typePath?.Image ?? undefined
                const sharp = imagepath
                  ? getImage(imagepath.localFiles[0])
                  : undefined
                const image = sharp ? (
                  <GatsbyImage image={sharp} alt={name} className="w-full" />
                ) : undefined

                const iconpath = typePath?.Icon ?? undefined

                const iconSharp = iconpath
                  ? getImage(iconpath.localFiles[0])
                  : undefined
                const icon = iconSharp ? (
                  <GatsbyImage
                    image={iconSharp}
                    alt={`${name} icon`}
                    className="w-full"
                  />
                ) : undefined

                return { name, text, image, icon, slug }
              })
            : undefined

          // ########## ONTSLOG ################
          const ontslogPath = itemPath?.Ontslog ?? undefined
          const ontslog = ontslogPath
            ? ontslogPath.map((item, index) => {
                const ontslogPath = item?.data ?? undefined
                const nameMn = ontslogPath?.nameMn?.trim() ?? undefined
                const nameEn = ontslogPath?.nameEn?.trim() ?? undefined
                const name = currentLanguage === "mn" ? nameMn : nameEn

                const slug = nameEn
                  ? nameEn.replace(/\s+/g, "-").toLowerCase()
                  : "#"

                const textMn = ontslogPath?.textMn?.trim() ?? undefined
                const textEn = ontslogPath?.textEn?.trim() ?? undefined
                const text = currentLanguage === "mn" ? textMn : textEn

                // const sharpBefore = ontslogPath?.Image?.locaFiles[0] ?? undefined
                // const sharp = getImage(sharpBefore)
                const imagepath = ontslogPath?.Image ?? undefined

                const sharp = imagepath
                  ? getImage(imagepath.localFiles[0])
                  : undefined
                const image = sharp ? (
                  <GatsbyImage image={sharp} alt={name} className="w-full" />
                ) : undefined

                const iconpath = ontslogPath?.Icon ?? undefined
                const iconSharp = iconpath
                  ? getImage(iconpath.localFiles[0])
                  : undefined
                const icon = iconSharp ? (
                  <GatsbyImage
                    image={iconSharp}
                    alt={`${name} icon`}
                    className="w-full"
                  />
                ) : undefined

                return { name, text, image, icon, slug }
              })
            : undefined

          // ########## LUXURY ################
          const luxuryPath = itemPath?.Luxury ?? undefined
          const luxury = luxuryPath
            ? luxuryPath.map((item, index) => {
                const luxuryPath = item?.data ?? undefined
                const nameMn = luxuryPath?.nameMn?.trim() ?? undefined
                const nameEn = luxuryPath?.nameEn?.trim() ?? undefined
                const name = currentLanguage === "mn" ? nameMn : nameEn

                const textMn = luxuryPath?.textMn?.trim() ?? undefined
                const textEn = luxuryPath?.textEn?.trim() ?? undefined
                const text = currentLanguage === "mn" ? textMn : textEn

                // const sharpBefore = luxuryPath?.Image?.locaFiles[0] ?? undefined
                // const sharp = getImage(sharpBefore)
                const imagepath = luxuryPath?.Image ?? undefined

                const sharp = imagepath
                  ? getImage(imagepath.localFiles[0])
                  : undefined
                const image = sharp ? (
                  <GatsbyImage image={sharp} alt={name} className="w-full" />
                ) : undefined

                const iconpath = luxuryPath?.Icon ?? undefined
                const iconSharp = iconpath
                  ? getImage(iconpath.localFiles[0])
                  : undefined
                const icon = iconSharp ? (
                  <GatsbyImage
                    image={iconSharp}
                    alt={`${name} icon`}
                    className="w-full"
                  />
                ) : undefined

                return { name, text, image, icon }
              })
            : undefined

          // ########## PATTERN ################
          const patternPath = itemPath?.Pattern ?? undefined
          const pattern = patternPath
            ? patternPath.map((item, index) => {
                const patternPath = item?.data ?? undefined
                const nameMn = patternPath?.nameMn?.trim() ?? undefined
                const nameEn = patternPath?.nameEn?.trim() ?? undefined
                const name = currentLanguage === "mn" ? nameMn : nameEn

                const slug = nameEn
                  ? nameEn.replace(/\s+/g, "-").toLowerCase()
                  : "#"

                const textMn = patternPath?.textMn?.trim() ?? undefined
                const textEn = patternPath?.textEn?.trim() ?? undefined
                const text = currentLanguage === "mn" ? textMn : textEn

                // const sharpBefore = patternPath?.Image?.locaFiles[0] ?? undefined
                // const sharp = getImage(sharpBefore)
                const imagepath = patternPath?.Image ?? undefined
                const sharp = imagepath
                  ? getImage(imagepath.localFiles[0])
                  : undefined
                const image = sharp ? (
                  <GatsbyImage image={sharp} alt={name} className="w-full" />
                ) : undefined

                const iconpath = patternPath?.Icon ?? undefined
                const iconSharp = iconpath
                  ? getImage(iconpath.localFiles[0])
                  : undefined
                const icon = iconSharp ? (
                  <GatsbyImage
                    image={iconSharp}
                    alt={`${name} icon`}
                    className="w-full"
                  />
                ) : undefined

                return { name, text, image, icon, slug }
              })
            : undefined

          // ########## ROOM ################
          const roomPath = itemPath?.Room ?? undefined
          const room = roomPath
            ? roomPath.map((item, index) => {
                const roomPath = item?.data ?? undefined
                const nameMn = roomPath?.nameMn?.trim() ?? undefined
                const nameEn = roomPath?.nameEn?.trim() ?? undefined
                const name = currentLanguage === "mn" ? nameMn : nameEn

                const slug = nameEn
                  ? nameEn.replace(/\s+/g, "-").toLowerCase()
                  : "#"

                const textMn = roomPath?.textMn?.trim() ?? undefined
                const textEn = roomPath?.textEn?.trim() ?? undefined
                const text = currentLanguage === "mn" ? textMn : textEn

                // const sharpBefore = roomPath?.Image?.locaFiles[0] ?? undefined
                // const sharp = getImage(sharpBefore)
                const imagepath = roomPath?.Image ?? undefined

                const sharp = imagepath
                  ? getImage(imagepath.localFiles[0])
                  : undefined
                const image = sharp ? (
                  <GatsbyImage image={sharp} alt={name} className="w-full" />
                ) : undefined

                const iconpath = roomPath?.Icon ?? undefined
                const iconSharp = iconpath
                  ? getImage(iconpath.localFiles[0])
                  : undefined
                const icon = iconSharp ? (
                  <GatsbyImage
                    image={iconSharp}
                    alt={`${name} icon`}
                    className="w-full"
                  />
                ) : undefined

                return { name, text, image, icon, slug }
              })
            : undefined

          // ######## TAILBAR ##########
          const tailbarEnBefore =
            itemPath?.tailbarEn?.internal?.content?.trim() ?? undefined
          const tailbarMnBefore =
            itemPath?.tailbarMn?.internal?.content?.trim() ?? undefined

          // const tempTailbarEn = tailbarEnBefore
          //   ? tailbarEnBefore.replace(/(\r\n|\n|\r)/gm, "")
          //   : undefined
          // const tempTailbarMn = tailbarMnBefore
          //   ? tailbarMnBefore.replace(/(\r\n|\n|\r)/gm, "")
          //   : undefined
          const tailbarEn =
            tailbarEnBefore !== undefined && tailbarEnBefore !== ""
              ? tailbarEnBefore
              : undefined
          const tailbarMn =
            tailbarMnBefore !== undefined && tailbarMnBefore !== ""
              ? tailbarMnBefore
              : undefined
          const tailbar = currentLanguage === "mn" ? tailbarMn : tailbarEn
          // const tailbar = (
          //   <div className="prose-sm">
          //     <ReactMarkdown children={tailbar} />
          //   </div>
          // )

          // ######### Other shinjuud ########
          const link = itemPath?.Link?.trim() ?? undefined
          const hot = itemPath?.Hot ?? false
          const newProduct = itemPath?.New ?? false
          const best = itemPath?.Bestseller ?? false
          const modern = itemPath?.Modern ?? false
          const ontslohBaraa = itemPath?.Ontsloh_baraa_bolgoh ?? false

          // ############ SALE ############
          const saleBeforeConverted = itemPath?.Sale ?? undefined
          const sale = saleBeforeConverted
            ? saleBeforeConverted * 100 + "%"
            : undefined

          // ############## MAINIMAGE ############
          const mainImagePath = itemPath?.mainImage ?? undefined
          const sharpMain = mainImagePath
            ? getImage(itemPath.mainImage.localFiles[0])
            : undefined
          const image = sharpMain ? (
            <GatsbyImage image={sharpMain} alt={name} className="w-full" />
          ) : undefined

          // ############ TUSLAH IMAGES ###########
          const tuslahImagePath = itemPath?.tuslahImages ?? undefined

          const pathTuslahImages = tuslahImagePath?.localFiles ?? undefined
          const tuslahImages = pathTuslahImages
            ? pathTuslahImages.map((item, index) => {
                const sharpTuslahImage = getImage(item)
                const tuslahImage = sharpTuslahImage ? (
                  <GatsbyImage
                    image={sharpTuslahImage}
                    alt={`${name}-${index + 1}`}
                    className="w-full"
                  />
                ) : undefined
                return tuslahImage
              })
            : undefined

          // ############ COLOR ###############
          const colorPath = itemPath?.Color ?? undefined
          const color = colorPath
            ? colorPath.map((item, index) => {
                const colorSubPath = item?.data ?? undefined
                const colorNameMn = colorSubPath
                  ? colorSubPath?.mn?.trim() ?? undefined
                  : undefined
                const colorNameEn = colorSubPath
                  ? colorSubPath?.en?.trim() ?? undefined
                  : undefined
                const colorName =
                  currentLanguage === "mn" ? colorNameMn : colorNameEn

                const colorHex = colorSubPath?.Hex_Color?.trim() ?? undefined
                const hex =
                  colorHex.charAt(0) === "#" ? colorHex : "#" + colorHex

                const genColor = colorSubPath?.generalColor ?? undefined
                const baraan = genColor ? genColor === "Бараандуу" : false

                return { colorName, hex, baraan }
              })
            : undefined

          // ############## COUNRTY ############
          const countryPath = itemPath?.Country ?? undefined
          const countrySubPath = countryPath ? countryPath[0]?.data : undefined
          const countryNameMn = countrySubPath ? countrySubPath.mn : undefined
          const countryNameEn = countrySubPath ? countrySubPath.en : undefined
          const country =
            currentLanguage === "mn" ? countryNameMn : countryNameEn

          const flagpath = countrySubPath?.Flag ?? undefined
          const countryFlagSharp = flagpath
            ? getImage(countrySubPath.Flag.localFiles[0])
            : undefined
          const flag = countryFlagSharp ? (
            <GatsbyImage
              image={countryFlagSharp}
              alt={country}
              className="w-full"
            />
          ) : undefined

          // ########### BRAND ##############
          const brandPath = itemPath?.Brand ?? undefined
          const brandSubPath = brandPath ? brandPath[0]?.data : undefined
          const brandNameMn = brandSubPath?.mn?.trim() ?? undefined
          const brandNameEn = brandSubPath?.en?.trim() ?? undefined
          const brand = currentLanguage === "mn" ? brandNameMn : brandNameEn

          const logopath = brandSubPath?.Logo ?? undefined

          const brandSharp = logopath
            ? getImage(brandSubPath.Logo.localFiles[0])
            : undefined
          const brandLogo = brandSharp ? (
            <GatsbyImage image={brandSharp} alt={brand} className="w-full" />
          ) : undefined

          // ########### SIZE ############
          const sizePath = itemPath?.Size ?? undefined
          const sizeSubPath = sizePath ? sizePath[0].data : undefined

          const urgunpath = sizeSubPath?.urgun ?? undefined
          const urgunWithoutUnit = urgunpath ?? undefined
          const urgun = urgunWithoutUnit
            ? `${urgunWithoutUnit.toString()}${
                currentLanguage === "mn" ? "см" : "cm"
              }`
            : undefined

          const urtpath = sizeSubPath?.urt ?? undefined
          const urtMn = urtpath ? urtpath + "м" : undefined
          const urtEn = urtpath ? urtpath + "m" : undefined
          const urt = currentLanguage === "mn" ? urtMn : urtEn

          // console.log(urt)

          const talbaipath = sizeSubPath?.talbai ?? undefined
          const talbaiMn = talbaipath ? talbaipath + "м" : undefined
          const talbaiEn = talbaipath ? talbaipath + "m" : undefined
          const talbai = currentLanguage === "mn" ? talbaiMn : talbaiEn

          return {
            id,
            productType,
            slug,
            name,
            angilal,
            ontslog,
            pattern,
            luxury,
            room,
            tailbar,
            link,
            hot,
            newProduct,
            best,
            modern,
            sale,
            image,
            tuslahImages,
            color,
            country,
            flag,
            brand,
            brandLogo,
            ontslohBaraa,
            urgun,
            urt,
            talbai,
            menuHide,
          }
        })
        .filter(item => item.menuHide !== true)
        .sort(function (a, b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
    : undefined
}

const query = graphql`
  {
    AllWallpapers: allAirtable(filter: { table: { eq: "Wallpaper" } }) {
      nodes {
        data {
          Name
          Type {
            data {
              nameMn: mn
              nameEn: en
              textMn: Mongol_tailbar
              textEn: English_tailbar
              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 800
                      quality: 100
                    )
                  }
                }
              }
              Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      placeholder: NONE
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
          Ontslog {
            data {
              nameMn: mn
              nameEn: en
              textMn: Mongol_tailbar
              textEn: English_tailbar
              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 800
                      quality: 100
                    )
                  }
                }
              }
              Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 100)
                  }
                }
              }
            }
          }
          Luxury {
            data {
              nameMn: mn
              nameEn: en
              textMn: Mongol_tailbar
              textEn: English_tailbar
              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 800
                      quality: 100
                    )
                  }
                }
              }
              Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      placeholder: NONE
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
          Pattern {
            data {
              nameMn: mn
              nameEn: en
              textMn: Mongol_tailbar
              textEn: English_tailbar
              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 800
                      quality: 100
                    )
                  }
                }
              }
              Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      placeholder: NONE
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
          Room {
            data {
              nameMn: mn
              nameEn: en
              textMn: Mongol_tailbar
              textEn: English_tailbar
              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 800
                      quality: 100
                    )
                  }
                }
              }
              Icon {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      placeholder: NONE
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
          tailbarEn: Nemelt_Tailbar_EN {
            internal {
              content
            }
          }
          tailbarMn: Nemelt_Tailbar_MN {
            internal {
              content
            }
          }
          Link
          Hot
          New
          Bestseller
          Sale
          Modern
          mainImage: Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 800, quality: 100)
              }
            }
          }
          tuslahImages: Tuslah_zuragnuud {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 800, quality: 100)
              }
            }
          }
          Color {
            data {
              mn
              en
              Hex_Color
              generalColor: General_color
            }
          }
          Country {
            data {
              mn
              en
              Flag {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 50, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          Brand {
            data {
              mn
              en
              Logo {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(
                      width: 100
                      placeholder: NONE
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
          Ontsloh_baraa_bolgoh
          Size {
            data {
              urgun: Urgun__cm_
              urt: Urt__m_
              talbai: _1_boodliin_talbai__m2_
            }
          }
          menuHide: Menu_Hide
        }
        id
      }
      totalCount
    }
  }
`
