import React, { useContext, useState } from "react"
import { Link } from "gatsby"
import { GatsbyContext } from "../context/context"
import TestDropdownMenu from "./TestDropdownMenu"
import { ArrowCircleRightIcon, ChevronDownIcon } from "@heroicons/react/solid"
import {
  ProductAndServiceText,
  WorkText,
  ContactText,
  BlogText,
} from "../graphql/QueryById"
import { BlogHide } from "../graphql/ControlQuery"

const NewHeaderMenu = ({ menuShow }) => {
  const { isMegaMenuOpen, showMega, hideMega } = useContext(GatsbyContext)
  const [value, setValue] = useState()

  // console.log(value)

  // console.log(ProductAndServiceText())

  const menu = [
    {
      name: ProductAndServiceText(),
      url: "#",
      dropdownMenu: true,
      menuHide: false,
    },
    {
      name: BlogText(),
      url: "/blog",
      menuHide: BlogHide(),
    },
    {
      name: WorkText(),
      url: "/works",
      menuHide: false,
    },
    {
      name: ContactText(),
      url: "/contact",
      menuHide: false,
    },
  ]

  // const [dropdownShow, setDropdownShow] = useState(false)

  const megaMenu = menu.map((item, index) => {
    const { name, url, dropdownMenu, menuHide } = item
    // const style =
    //   "flex items-center px-2 text-xs lg:px-5 lg:font-bold md:text-sm uppercase text-gray-700 group hover:text-gray-900 lg:tracking-normal font-black tracking-wider"
    const style = `flex items-center px-2 text-xs lg:px-5 lg:font-semibold md:text-base  text-gray-900 group hover:text-black  font-normal outline-none group-hover:outline-none active:outline-none hover:outline-none focus:outline-none py-10 text-center`

    return dropdownMenu ? (
      <button
        key={index}
        // to={url}
        type="button"
        className={style}
        // onMouseEnter={() => menuShow(true)}
        // onMouseLeave={() => menuShow(false)}
        onMouseEnter={showMega}
        onMouseLeave={hideMega}
        onClick={showMega}
      >
        <div>{name}</div>
        {/* Menunii hoino garah temdeg */}
        {/* <ChevronDownIcon className="w-4 h-4 md:hidden" aria-hidden="true" /> */}
      </button>
    ) : (
      !menuHide && (
        <Link
          key={`${index} dan`}
          to={url}
          className={style}
          onClick={() => setValue(index)}
        >
          <div>{name}</div>
        </Link>
      )
    )
  })

  return (
    <div className="relative flex items-stretch justify-end flex-1">
      {megaMenu}
    </div>
  )
}

export default NewHeaderMenu
