import React, { Fragment, useContext, useEffect, useState } from "react"
import { Dialog, Transition, Menu } from "@headlessui/react"
import { MenuAlt1Icon, XIcon } from "@heroicons/react/solid"
import { GatsbyContext } from "../context/context"
import MobileMenu from "./MobileMenu"
import fabric from "../images/cheap_diagonal_fabric.png"
import SecondNewSmallLanguageSwitcher from "./SecondNewSmallLanguageSwitcher"
import BackgroundSvg from "./BackgroundSvg"
import { LanguageChangerHide } from "../graphql/ControlQuery"

export default function MenuModal({}) {
  const { modalMenuOpen, showModalMenu, hideModalMenu } = useContext(
    GatsbyContext
  )
  let [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    modalMenuOpen ? openModal() : closeModal()
  }, [modalMenuOpen])

  function closeModal() {
    setIsOpen(false)
    hideModalMenu()
  }

  function openModal() {
    setIsOpen(true)
    showModalMenu()
  }

  function handleClick() {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Transition
        appear
        show={isOpen}
        as={Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto md:hidden"
          open={isOpen}
          onClose={closeModal}
        >
          <div className="flex items-start justify-center min-h-screen ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="flex w-full p-6 overflow-hidden text-left align-middle transition-all transform bg-opacity-100 shadow-xl bg-gray-50"
                // style={{ backgroundImage: `url(${fabric})` }}
              >
                <BackgroundSvg />
                <button
                  onClick={closeModal}
                  className="absolute z-50 p-1 text-3xl bg-transparent bg-black border-transparent rounded-full outline-none top-4 right-4 focus:outline-none active:outline-none bg-opacity-20"
                >
                  <XIcon className="w-6 h-6 text-white hover:text-gray-900 " />
                </button>

                {!LanguageChangerHide() && (
                  <div className="absolute z-10 top-2 left-2 opacity-80">
                    <SecondNewSmallLanguageSwitcher showText />
                  </div>
                )}

                <div className="max-w-md min-h-xl">
                  <MobileMenu closeModal={closeModal} />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
