import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"
import Title from "../components/Title"
import { useTranslation } from "react-i18next"
import { CurrentLanguage } from "../components/MenuItems"
import { render } from "react-dom"

export const MenuImage = () => {
  const { MenuImage } = useStaticQuery(query)

  const path = MenuImage?.nodes ? MenuImage.nodes[0] : undefined
  const imagePath = path?.data?.Image ?? undefined
  const sharp = imagePath ? getImage(imagePath.localFiles[0]) : undefined
  const menuImage = sharp ? (
    <GatsbyImage
      image={sharp}
      alt="Other products menu image"
      className="w-full"
    />
  ) : undefined
  return menuImage
}

export const AllOtherProducts = () => {
  const currentLanguage = CurrentLanguage()
  const mn = currentLanguage === "mn"
  const { AllOtherProducts } = useStaticQuery(query)

  const path = AllOtherProducts?.nodes ?? undefined
  // console.log(path)
  return path
    ? path
        .map((item, index) => {
          const path = item?.data ?? undefined
          const nameMn = path?.nameMn?.trim() ?? undefined
          const nameEn = path?.nameEn?.trim() ?? undefined
          const name = mn ? nameMn : nameEn

          // const link = "other"

          const mdMn = path?.mdMn?.internal?.content?.trim() ?? undefined
          const mdEn = path?.mdEn?.internal?.content?.trim() ?? undefined
          const mdRaw = mn ? mdMn : mdEn
          const md = (
            <div key={index} className="px-4 prose-sm sm:prose">
              <ReactMarkdown
                plugins={[[gfm, { singleTilde: false }]]}
                children={mdRaw}
              />
              {/* <ReactMarkdown children={mdRaw} /> */}
              {/* {render(<ReactMarkdown source={mdRaw} />)} */}
              {/* <ReactMarkdown remarkPlugins={[gfm]} children={mdRaw} /> */}
              {/* {render(<ReactMarkdown>{mdRaw}</ReactMarkdown>)} */}
              {/* {render(<ReactMarkdown remarkPlugins={[gfm]} children={mdRaw} />)} */}
              {/* {mdRaw} */}
            </div>
          )

          const sharppath = path?.Image ? path.Image?.localFiles : undefined
          const images = sharppath?.map((item, index) => {
            const sharp = item ? getImage(item) : undefined
            const image = sharp ? (
              <GatsbyImage
                key={index}
                image={sharp}
                alt={`${name} - ${index}`}
                className="w-full"
              />
            ) : undefined
            return image
          })

          // const sharp = sharppath ? getImage(sharppath.localFiles[0]) : undefined
          // const image = sharp ? (
          //   <GatsbyImage image={sharp} alt={name} className="w-full" />
          // ) : undefined

          const countryPath = path?.country ? path.country[0] : undefined
          const countrySubPath = countryPath ? countryPath?.data : undefined
          const countryNameMn = countrySubPath?.mn ?? undefined
          const countryNameEn = countrySubPath?.en ?? undefined
          const countryName = mn ? countryNameMn : countryNameEn
          const countrySharpPath = countrySubPath?.Flag ?? undefined
          const sharpFlag = countrySharpPath
            ? getImage(countrySharpPath.localFiles[0])
            : undefined
          const flag = sharpFlag ? (
            <GatsbyImage image={sharpFlag} alt={name} className="w-full" />
          ) : undefined

          const menuHide = path?.menuHide ?? false

          return { name, md, images, countryName, flag, menuHide }
        })
        .filter(Boolean)
        .filter(item => !item.menuHide)
    : undefined
}

const query = graphql`
  {
    MenuImage: allAirtable(
      filter: {
        table: { eq: "Other images" }
        id: { eq: "136184ce-1f0e-51c2-83b9-14e7b78cec69" }
      }
    ) {
      nodes {
        data {
          Name
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  quality: 100
                  width: 200
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
    AllOtherProducts: allAirtable(
      filter: { table: { eq: "Other products" } }
      sort: { fields: id, order: ASC }
    ) {
      nodes {
        data {
          nameMn: Name_mongol
          nameEn: Name_english
          mdMn: Tailbar_mongol {
            internal {
              content
            }
          }
          mdEn: Tailbar_english {
            internal {
              content
            }
          }
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
              }
            }
          }
          country: Made_In {
            data {
              mn
              en
              Flag {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 100, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          menuHide: Menu_Hide
        }
      }
    }
  }
`
