import React from "react"
import { useTranslation } from "react-i18next"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

export const mn = () => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  return currentLanguage === "mn"
}

export const MoreText = () => {
  const mon = mn()
  const { MoreText } = useStaticQuery(query)
  const path = MoreText?.nodes ? MoreText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const WorkTitle = () => {
  const mon = mn()
  const { WorkTitle } = useStaticQuery(query)
  const path = WorkTitle?.nodes ? WorkTitle.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const ProductAndServiceText = () => {
  const mon = mn()
  const { ProductAndServiceText } = useStaticQuery(query)
  const path = ProductAndServiceText?.nodes
    ? ProductAndServiceText.nodes[0]
    : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const WorkText = () => {
  const mon = mn()
  const { WorkText } = useStaticQuery(query)
  const path = WorkText?.nodes ? WorkText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const ContactText = () => {
  const mon = mn()
  const { ContactText } = useStaticQuery(query)
  const path = ContactText?.nodes ? ContactText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const OthersText = () => {
  const mon = mn()
  const { OthersText } = useStaticQuery(query)
  const path = OthersText?.nodes ? OthersText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const GeneralDescText = () => {
  const mon = mn()
  const { GeneralDescText } = useStaticQuery(query)
  const path = GeneralDescText?.nodes ? GeneralDescText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const AngilalText = () => {
  const mon = mn()
  const { AngilalText } = useStaticQuery(query)
  const path = AngilalText?.nodes ? AngilalText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const OntslogText = () => {
  const mon = mn()
  const { OntslogText } = useStaticQuery(query)
  const path = OntslogText?.nodes ? OntslogText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const ColorText = () => {
  const mon = mn()
  const { ColorText } = useStaticQuery(query)
  const path = ColorText?.nodes ? ColorText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const RoomText = () => {
  const mon = mn()
  const { RoomText } = useStaticQuery(query)
  const path = RoomText?.nodes ? RoomText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const PatternText = () => {
  const mon = mn()
  const { PatternText } = useStaticQuery(query)
  const path = PatternText?.nodes ? PatternText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const LuxuryText = () => {
  const mon = mn()
  const { LuxuryText } = useStaticQuery(query)
  const path = LuxuryText?.nodes ? LuxuryText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const ProductIdText = () => {
  const mon = mn()
  const { ProductIdText } = useStaticQuery(query)
  const path = ProductIdText?.nodes ? ProductIdText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const AllText = () => {
  const mon = mn()
  const { AllText } = useStaticQuery(query)
  const path = AllText?.nodes ? AllText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const NameText = () => {
  const mon = mn()
  const { NameText } = useStaticQuery(query)
  const path = NameText?.nodes ? NameText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const YndsenShinjText = () => {
  const mon = mn()
  const { YndsenShinjText } = useStaticQuery(query)
  const path = YndsenShinjText?.nodes ? YndsenShinjText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const TypeText = () => {
  const mon = mn()
  const { TypeText } = useStaticQuery(query)
  const path = TypeText?.nodes ? TypeText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const CountryText = () => {
  const mon = mn()
  const { CountryText } = useStaticQuery(query)
  const path = CountryText?.nodes ? CountryText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const BrandText = () => {
  const mon = mn()
  const { BrandText } = useStaticQuery(query)
  const path = BrandText?.nodes ? BrandText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const TailbarText = () => {
  const mon = mn()
  const { TailbarText } = useStaticQuery(query)
  const path = TailbarText?.nodes ? TailbarText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const GoToText = () => {
  const mon = mn()
  const { GoToText } = useStaticQuery(query)
  const path = GoToText?.nodes ? GoToText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const OntslohText = () => {
  const mon = mn()
  const { OntslohText } = useStaticQuery(query)
  const path = OntslohText?.nodes ? OntslohText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const ProjectNameText = () => {
  const mon = mn()
  const { ProjectNameText } = useStaticQuery(query)
  const path = ProjectNameText?.nodes ? ProjectNameText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const ZahialagchText = () => {
  const mon = mn()
  const { ZahialagchText } = useStaticQuery(query)
  const path = ZahialagchText?.nodes ? ZahialagchText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const OgnooText = () => {
  const mon = mn()
  const { OgnooText } = useStaticQuery(query)
  const path = OgnooText?.nodes ? OgnooText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const ProductText = () => {
  const mon = mn()
  const { ProductText } = useStaticQuery(query)
  const path = ProductText?.nodes ? ProductText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const ThanksForMessagingText = () => {
  const mon = mn()
  const { ThanksForMessagingText } = useStaticQuery(query)
  const path = ThanksForMessagingText?.nodes
    ? ThanksForMessagingText.nodes[0]
    : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const SendText = () => {
  const mon = mn()
  const { SendText } = useStaticQuery(query)
  const path = SendText?.nodes ? SendText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const SanalHuseltText = () => {
  const mon = mn()
  const { SanalHuseltText } = useStaticQuery(query)
  const path = SanalHuseltText?.nodes ? SanalHuseltText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const EmailText = () => {
  const mon = mn()
  const { EmailText } = useStaticQuery(query)
  const path = EmailText?.nodes ? EmailText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const SanalHuseltIlgeehText = () => {
  const mon = mn()
  const { SanalHuseltIlgeehText } = useStaticQuery(query)
  const path = SanalHuseltIlgeehText?.nodes
    ? SanalHuseltIlgeehText.nodes[0]
    : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const PhoneText = () => {
  const mon = mn()
  const { PhoneText } = useStaticQuery(query)
  const path = PhoneText?.nodes ? PhoneText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const CopyrightText = () => {
  const mon = mn()
  const { CopyrightText } = useStaticQuery(query)
  const path = CopyrightText?.nodes ? CopyrightText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const AddressText = () => {
  const mon = mn()
  const { AddressText } = useStaticQuery(query)
  const path = AddressText?.nodes ? AddressText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const ChangeLanguageText = () => {
  const mon = mn()
  const { ChangeLanguageText } = useStaticQuery(query)
  const path = ChangeLanguageText?.nodes
    ? ChangeLanguageText.nodes[0]
    : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const HomePageText = () => {
  const mon = mn()
  const { HomePageText } = useStaticQuery(query)
  const path = HomePageText?.nodes ? HomePageText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const UgsraltText = () => {
  const mon = mn()
  const { UgsraltText } = useStaticQuery(query)
  const path = UgsraltText?.nodes ? UgsraltText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const ServiceText = () => {
  const mon = mn()
  const { ServiceText } = useStaticQuery(query)
  const path = ServiceText?.nodes ? ServiceText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const BlogText = () => {
  const mon = mn()
  const { BlogText } = useStaticQuery(query)
  const path = BlogText?.nodes ? BlogText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const PartnersText = () => {
  const mon = mn()
  const { PartnersText } = useStaticQuery(query)
  const path = PartnersText?.nodes ? PartnersText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const SizeText = () => {
  const mon = mn()
  const { SizeText } = useStaticQuery(query)
  const path = SizeText?.nodes ? SizeText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const WidthText = () => {
  const mon = mn()
  const { WidthText } = useStaticQuery(query)
  const path = WidthText?.nodes ? WidthText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const RollLengthText = () => {
  const mon = mn()
  const { RollLengthText } = useStaticQuery(query)
  const path = RollLengthText?.nodes ? RollLengthText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const SurfaceText = () => {
  const mon = mn()
  const { SurfaceText } = useStaticQuery(query)
  const path = SurfaceText?.nodes ? SurfaceText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const SaleText = () => {
  const mon = mn()
  const { SaleText } = useStaticQuery(query)
  const path = SaleText?.nodes ? SaleText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const TypeWallLongText = () => {
  const mon = mn()
  const { TypeWallLongText } = useStaticQuery(query)
  const path = TypeWallLongText?.nodes ? TypeWallLongText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const ColorWallLongText = () => {
  const mon = mn()
  const { ColorWallLongText } = useStaticQuery(query)
  const path = ColorWallLongText?.nodes ? ColorWallLongText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const DarkColorWallLongText = () => {
  const mon = mn()
  const { DarkColorWallLongText } = useStaticQuery(query)
  const path = DarkColorWallLongText?.nodes ? DarkColorWallLongText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

export const PatternLongText = () => {
  const mon = mn()
  const { PatternLongText } = useStaticQuery(query)
  const path = PatternLongText?.nodes ? PatternLongText.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  const nameMn = subPath?.nameMn ?? undefined
  const nameEn = subPath?.nameEn ?? undefined
  return mon ? nameMn : nameEn
}

// function exportConst(data) {
//   data.map((item, index) => {
//     export const item = () => {
//       const mon = mn()
//       const { OntslogText } = useStaticQuery(query)
//       const path = OntslogText?.nodes ? OntslogText.nodes[0] : undefined
//       const subPath = path?.data ?? undefined
//       const nameMn = subPath?.nameMn ?? undefined
//       const nameEn = subPath?.nameEn ?? undefined
//       return mon ? nameMn : nameEn
//     }
//   })
// }

const query = graphql`
  {
    MoreText: allAirtable(
      filter: { id: { eq: "945b1383-55dd-5daf-b453-6659ab41d956" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    WorkTitle: allAirtable(
      filter: { id: { eq: "bdcbdb50-0ad2-5ab8-8641-d6b3bd184ea3" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    PatternLongText: allAirtable(
      filter: { id: { eq: "ec2189a1-01e1-5ccd-ab07-1976aab49f3e" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    ColorWallLongText: allAirtable(
      filter: { id: { eq: "a951a846-a307-583c-931a-aaa05e7fe731" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    DarkColorWallLongText: allAirtable(
      filter: { id: { eq: "46a80cb0-3840-53a3-999e-051afe2e2ed3" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    TypeWallLongText: allAirtable(
      filter: { id: { eq: "ef07fc95-6580-519c-9731-80dcf6854162" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    SaleText: allAirtable(
      filter: { id: { eq: "8e0653f6-8ada-58cb-a4d0-991d565c3fe0" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    RollLengthText: allAirtable(
      filter: { id: { eq: "40126f2a-7370-5c1f-bee9-dc3fa15d60fa" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    SurfaceText: allAirtable(
      filter: { id: { eq: "20c16c80-f3a9-5bbd-af63-8f98768173be" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    WidthText: allAirtable(
      filter: { id: { eq: "4cb8480b-8862-5c39-b42d-487c28176d15" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    ProductAndServiceText: allAirtable(
      filter: { id: { eq: "4eeefea1-feb0-5dbc-a297-da77b085a7cd" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    WorkText: allAirtable(
      filter: { id: { eq: "c766354c-6150-5ffe-9da7-aafe6696c429" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    ContactText: allAirtable(
      filter: { id: { eq: "d6aea522-f9ab-5f9f-ab85-b7df3ddc1c22" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    OthersText: allAirtable(
      filter: { id: { eq: "fe4c3220-e4b2-5442-aad3-76f80ff3ba88" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    GeneralDescText: allAirtable(
      filter: { id: { eq: "b701791a-8864-507e-9131-90d6b4289679" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    AngilalText: allAirtable(
      filter: { id: { eq: "ac3619d7-c73b-5571-9c22-80c8174687c2" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    OntslogText: allAirtable(
      filter: { id: { eq: "aada2a90-f7a8-5b85-ae0d-7ebdd0328264" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    ColorText: allAirtable(
      filter: { id: { eq: "5d624e51-b693-578c-a69e-19f136ae5ec9" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    RoomText: allAirtable(
      filter: { id: { eq: "7d6a6883-46e0-5998-a187-1c61307b5260" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    PatternText: allAirtable(
      filter: { id: { eq: "0520cb4e-d727-52c9-8545-4ea1989e216e" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    LuxuryText: allAirtable(
      filter: { id: { eq: "5e256fa0-cdac-531b-9812-0a93bc5700b2" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    ProductIdText: allAirtable(
      filter: { id: { eq: "df50cf2b-11f4-5fd8-a20b-824431a4d4c8" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    AllText: allAirtable(
      filter: { id: { eq: "f20097a0-28b5-50f3-ad16-3eaa1945869d" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    NameText: allAirtable(
      filter: { id: { eq: "b91f87f3-2e13-54ee-a8a7-aef9305d88ce" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    YndsenShinjText: allAirtable(
      filter: { id: { eq: "7daf5ea8-da14-5d9d-81bc-8cf9b817e8e3" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    TypeText: allAirtable(
      filter: { id: { eq: "8d320225-0fd6-5670-a6fa-7577af725481" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    CountryText: allAirtable(
      filter: { id: { eq: "632c1499-78a7-59a3-8851-2307f498a50c" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    BrandText: allAirtable(
      filter: { id: { eq: "b29ef1bd-cb24-55b0-abbd-716812578fd2" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    TailbarText: allAirtable(
      filter: { id: { eq: "c8def01b-d70a-58bc-8984-a00cdcc29830" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    GoToText: allAirtable(
      filter: { id: { eq: "a23d5fc9-f8be-5a19-b29e-0b2776f4ddff" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    OntslohText: allAirtable(
      filter: { id: { eq: "f0d1d43f-7482-5b4e-87f8-62df00e315bf" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    ProjectNameText: allAirtable(
      filter: { id: { eq: "2be6511f-c6ab-5205-b0ca-5c32846a6d13" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    ZahialagchText: allAirtable(
      filter: { id: { eq: "d359b6db-db98-5349-bbb2-dafd6436954c" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    OgnooText: allAirtable(
      filter: { id: { eq: "08c17813-ba9e-5581-9e5c-498d70d4979c" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    ProductText: allAirtable(
      filter: { id: { eq: "b4541611-02f8-5b73-90db-2ecf3fc0eeae" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    ThanksForMessagingText: allAirtable(
      filter: { id: { eq: "be3b4fa9-2072-5ea7-89bb-2a1485d0f47e" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    SendText: allAirtable(
      filter: { id: { eq: "65da939b-6653-5164-b24b-d965eb304562" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    SanalHuseltText: allAirtable(
      filter: { id: { eq: "e1c56b3a-7674-54bf-9873-9ac3cb74ac52" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    EmailText: allAirtable(
      filter: { id: { eq: "80560ae2-b278-5ea7-ad43-daacb30505db" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    SanalHuseltIlgeehText: allAirtable(
      filter: { id: { eq: "663f010a-54dc-59d2-bc3a-5fa0c26766f6" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    PhoneText: allAirtable(
      filter: { id: { eq: "01da100d-7762-554a-a757-310d1b632bbc" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    CopyrightText: allAirtable(
      filter: { id: { eq: "10d301cc-34ca-54dc-85b3-55a22aa0625b" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    AddressText: allAirtable(
      filter: { id: { eq: "e84b0af3-bc02-5255-b8ba-fee16a82baab" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    ChangeLanguageText: allAirtable(
      filter: { id: { eq: "932b7737-554c-5e15-b3a6-31a35fb376df" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    HomePageText: allAirtable(
      filter: { id: { eq: "d0521182-dd09-5e69-b7db-e79c75b77bd4" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    UgsraltText: allAirtable(
      filter: { id: { eq: "a25990f6-896a-533b-ab10-2dbc2ba9b7ce" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    ServiceText: allAirtable(
      filter: { id: { eq: "b4eb7a45-2d3a-5ab2-85c8-979980002ec7" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    BlogText: allAirtable(
      filter: { id: { eq: "b8e56cad-4157-55e2-8f75-431561745155" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    PartnersText: allAirtable(
      filter: { id: { eq: "be88d318-57c9-5f18-a963-d949ae10e6bc" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
    SizeText: allAirtable(
      filter: { id: { eq: "fa8fe143-4c08-52bf-8a60-d27d866590b0" } }
    ) {
      nodes {
        data {
          nameMn: Mongol_text
          nameEn: English_text
        }
      }
    }
  }
`
