import React, { useContext, useEffect, useState } from "react"
import { GatsbyContext } from "../context/context"
import TopSubMenu from "./TopSubMenu"
import fabric from "../images/cheap_diagonal_fabric.png"

const ProductAndServiceMegaMenu = () => {
  const { isMegaMenuOpen, showMega, hideMega } = useContext(GatsbyContext)
  const [menuLength, setMenuLength] = useState(null)
  // console.log(menuLength)

  return (
    <div
      className={`absolute left-0 w-full  overflow-hidden transition duration-700 ease-in-out bg-gray-100 border rounded-b shadow-lg auto-rows-auto top-14 md:top-24 z-50 p-2 mt-2 h-60 ${
        isMegaMenuOpen ? "" : "hidden"
      } ${menuLength < 6 ? "h-40" : "h-64"}`}
      onMouseEnter={showMega}
      onMouseLeave={hideMega}
      // style={{ backgroundImage: `url(${fabric})` }}
    >
      {/* {TopSubMenu()} */}
      <TopSubMenu setMenuLength={setMenuLength} />
    </div>
  )
}

export default ProductAndServiceMegaMenu
