import React from "react"
import { useTranslation } from "react-i18next"
import { graphql, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

export const mn = () => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  return currentLanguage === "mn"
}

export const WallHide = () => {
  const { WallHide } = useStaticQuery(query)
  const path = WallHide?.nodes ? WallHide.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  return subPath?.Hide ?? null
}

export const FloorHide = () => {
  const { FloorHide } = useStaticQuery(query)
  const path = FloorHide?.nodes ? FloorHide.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  return subPath?.Hide ?? null
}


export const SidingHide = () => {
  const { SidingHide } = useStaticQuery(query)
  const path = SidingHide?.nodes ? SidingHide.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  return subPath?.Hide ?? null
}

export const PtileHide = () => {
  const { PtileHide } = useStaticQuery(query)
  const path = PtileHide?.nodes ? PtileHide.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  return subPath?.Hide ?? null
}

export const CarpetHide = () => {
  const { CarpetHide } = useStaticQuery(query)
  const path = CarpetHide?.nodes ? CarpetHide.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  return subPath?.Hide ?? null
}

export const CurtainHide = () => {
  const { CurtainHide } = useStaticQuery(query)
  const path = CurtainHide?.nodes ? CurtainHide.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  return subPath?.Hide ?? null
}

export const LanguageChangerHide = () => {
  const { LanguageChangerHide } = useStaticQuery(query)
  const path = LanguageChangerHide?.nodes
    ? LanguageChangerHide.nodes[0]
    : undefined
  const subPath = path?.data ?? undefined
  return subPath?.Hide ?? null
}

export const FacebookHide = () => {
  const { FacebookHide } = useStaticQuery(query)
  const path = FacebookHide?.nodes ? FacebookHide.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  return subPath?.Hide ?? null
}

export const InstagramHide = () => {
  const { InstagramHide } = useStaticQuery(query)
  const path = InstagramHide?.nodes ? InstagramHide.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  return subPath?.Hide ?? null
}

export const SaleHide = () => {
  const { SaleHide } = useStaticQuery(query)
  const path = SaleHide?.nodes ? SaleHide.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  return subPath?.Hide ?? null
}

export const BlogHide = () => {
  const { BlogHide } = useStaticQuery(query)
  const path = BlogHide?.nodes ? BlogHide.nodes[0] : undefined
  const subPath = path?.data ?? undefined
  return subPath?.Hide ?? null
}

const query = graphql`
  {
    WallHide: allAirtable(
      filter: { id: { eq: "23940172-c092-5a89-b39b-9f3e7382ff1a" } }
    ) {
      nodes {
        data {
          Hide
        }
      }
    }
    FloorHide: allAirtable(
      filter: { id: { eq: "0062c3e3-c23f-5e14-a639-c5c74007d145" } }
    ) {
      nodes {
        data {
          Hide
        }
      }
    }
    SidingHide: allAirtable(
      filter: { id: { eq: "a1c8f93c-bb6c-5e5b-8af6-bdd3694013fd" } }
    ) {
      nodes {
        data {
          Hide
        }
      }
    }
    PtileHide: allAirtable(
      filter: { id: { eq: "ccb4756c-8fe2-5502-a5b2-21244744cf22" } }
    ) {
      nodes {
        data {
          Hide
        }
      }
    }
    CarpetHide: allAirtable(
      filter: { id: { eq: "55dbb797-2fd3-5653-9ac4-406d58631f14" } }
    ) {
      nodes {
        data {
          Hide
        }
      }
    }
    CurtainHide: allAirtable(
      filter: { id: { eq: "9fe75c4e-1c0e-56fe-86e8-5f2370f13e59" } }
    ) {
      nodes {
        data {
          Hide
        }
      }
    }
    LanguageChangerHide: allAirtable(
      filter: { id: { eq: "ba265c01-bd08-5c54-9c47-5895587e6fa8" } }
    ) {
      nodes {
        data {
          Hide
        }
      }
    }
    FacebookHide: allAirtable(
      filter: { id: { eq: "fc03b51d-be66-5637-b204-3836df030adc" } }
    ) {
      nodes {
        data {
          Hide
        }
      }
    }
    InstagramHide: allAirtable(
      filter: { id: { eq: "639fcbb4-dca6-5445-a1a0-3d620332c1e8" } }
    ) {
      nodes {
        data {
          Hide
        }
      }
    }
    SaleHide: allAirtable(
      filter: { id: { eq: "3509647e-710a-554d-9d18-9ef63b27cc0e" } }
    ) {
      nodes {
        data {
          Hide
        }
      }
    }
    BlogHide: allAirtable(
      filter: { table: { eq: "Control" }, data: { Name: { eq: "Blog" } } }
    ) {
      nodes {
        data {
          Hide
        }
      }
    }
  }
`
