import React from "react"
import { Menu, Transition } from "@headlessui/react"
import { Link } from "gatsby"
// import { WallpaperDesc } from "../graphql/SmallQueries"
import { RaisedFloorDesc } from "../graphql/RaisedFloorSmallQueries"
import { Desc } from "../graphql/SidingSmallQueries"
import { HuldaasDesc } from "../graphql/HuldaasSmallQueries"
import { HivsentserDesc } from "../graphql/HivsentserSmallQueries"
import { CurtainDesc } from "../graphql/CurtainSmallQueries"
import { MenuImage } from "../graphql/OtherSmallQueries"
import { WallpaperDescription } from "../graphql/WallpaperMenuQuery"
import { AllWallpapers } from "../graphql/WallpaperPageFullQuery"
import { AllRaisedFloor } from "../graphql/RaisedFloorQuery"
import { AllSiding } from "../graphql/AllSidingQuery"
import { AllHivsentser } from "../graphql/AllHivsentserQuery"
import { AllHuldaas } from "../graphql/AllHuldaasQuery"
import { AllCurtain } from "../graphql/AllCurtainQuery"
import {
  ProductIdText,
  MoreText,
  ColorText,
  RoomText,
  PatternText,
  OntslogText,
  LuxuryText,
  WorkText,
  ContactText,
  OthersText,
  BlogText
} from "../graphql/QueryById"

import soWhite from "../images/so-white.png"
import { FaPhoneAlt } from "react-icons/fa"
import ButtomSocialMenu from "./ButtomSocialMenu"
import {
  CarpetHide,
  CurtainHide,
  FloorHide,
  PtileHide,
  SidingHide,
  WallHide,
  BlogHide,
} from "../graphql/ControlQuery"

const MobileMenu = ({ closeModal }) => {
  const wallCount = AllWallpapers().length
  const floorCount = AllRaisedFloor().length
  const sidingCount = AllSiding().length
  const hivsentserCount = AllHivsentser().length
  const huldaasCount = AllHuldaas().length
  const curtainCount = AllCurtain().length
  const menuItem = [
    {
      name: WallpaperDescription().name,
      url: "/wallpaper",
      // icon: <GiRolledCloth />,
      // image: WallpaperDescription().imageMenu,
      menuHide: WallHide(),
      count: wallCount,
    },
    {
      name: RaisedFloorDesc()[0].name,
      url: "/raised-floor",
      // icon: <ImStack />,
      // image: RaisedFloorDesc()[0].imageMenu,
      menuHide: FloorHide(),
      count: floorCount,
    },
    {
      name: Desc()[0].name,
      url: "/siding",
      // icon: <GiBrickWall />,
      // image: Desc()[0].imageMenu,
      menuHide: SidingHide(),
      count: sidingCount,
    },
    {
      name: HuldaasDesc()[0].name,
      url: "/p-tile",
      // icon: <GiHeatHaze />,
      // image: HuldaasDesc()[0].imageMenu,
      menuHide: PtileHide(),
      count: huldaasCount,
    },
    {
      name: HivsentserDesc()[0].name,
      url: "/carpet-tile",
      // icon: <FaChessBoard />,
      // image: HivsentserDesc()[0].imageMenu,
      menuHide: CarpetHide(),
      count: hivsentserCount,
    },
    {
      name: CurtainDesc()[0].name,
      url: "/curtain-rail",
      // icon: <GiTheaterCurtains />,
      // image: CurtainDesc()[0].imageMenu,
      menuHide: CurtainHide(),
      count: curtainCount,
    },
    {
      name: OthersText(),
      url: "/other",
      // icon: <GiAbstract077 />,
      // image: MenuImage(),
      menuHide: false,
      count: 1,
    },
    // {
    //   name: "Зөвлөгөө, мэдээлэл",
    //   url: "/wallpaper",
    //   // image: "",
    //   menuHide: false,
    //   count: 1,
    // },
    {
      name: WorkText(),
      url: "/works",
      // image: "",
      menuHide: false,
      count: 1,
    },
    {
      name: BlogText(),
      url: "/blog",
      // image: "",
      menuHide: BlogHide(),
      count: 1,
    },
    {
      name: ContactText(),
      url: "/contact",
      icon: <FaPhoneAlt />,
      // image: "",
      menuHide: false,
      count: 1,
    },
  ]

  const menus = menuItem
    .map((item, index) => {
      return (
        item.count &&
        !item.menuHide && (
          <Menu.Item key={index}>
            {({ active }) => (
              <Link
                to={item.url}
                className={`${active && ""}`}
                onClick={() => closeModal()}
              >
                <div
                  className="absolute inset-0 flex flex-grow object-cover w-full h-full bg-no-repeat bg-cover opacity-100 hover:opacity-100 focus:opacity-100"
                  // style={{ backgroundImage: `url(${soWhite})` }}
                >
                  {/* {item.image} */}
                </div>
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="items-end px-4 py-1 uppercase">
                    {item.name}
                  </div>
                </div>
              </Link>
            )}
          </Menu.Item>
        )
      )
    })
    .filter(Boolean)

  const menuItemsCount = menus.length

  return (
    <>
      <Menu className="absolute inset-0 grid grid-cols-2 gap-0.5 pt-16 px-2 items-center">
        <Menu.Items as="ul" static>
          {/* {menus} */}
          {menuItemsCount === 7 && (
            <>
              <div className="relative flex col-span-2 px-2 m-0.5 text-xl font-light tracking-widest text-center text-black border-2 border-gray-200 rounded h-28">
                {menus[0]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-28">
                {menus[1]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-28">
                {menus[2]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-28">
                {menus[3]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-28">
                {menus[4]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-28">
                {menus[5]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-28">
                {menus[6]}
              </div>
            </>
          )}
          {menuItemsCount === 8 && (
            <>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-gray-700  border-2 text-sm border-gray-200 rounded h-24">
                {menus[0]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-gray-700  border-2 text-sm border-gray-200 rounded h-24">
                {menus[1]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-gray-700  border-2 text-sm border-gray-200 rounded h-24">
                {menus[2]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-gray-700  border-2 text-sm border-gray-200 rounded h-24">
                {menus[3]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-gray-700  border-2 text-sm border-gray-200 rounded h-24">
                {menus[4]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-gray-700  border-2 text-sm border-gray-200 rounded h-24">
                {menus[5]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-gray-700  border-2 text-sm border-gray-200 rounded h-24">
                {menus[6]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-gray-700  border-2 text-sm border-gray-200 rounded h-24">
                {menus[7]}
              </div>
            </>
          )}
          {menuItemsCount === 9 && (
            <>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20 col-span-2">
                {menus[0]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[1]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[2]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[3]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[4]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[5]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[6]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[7]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[8]}
              </div>
            </>
          )}
          {menuItemsCount === 10 && (
            <>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[0]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[1]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[2]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[3]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[4]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[5]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[6]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[7]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[8]}
              </div>
              <div className="relative flex px-2 m-0.5 font-normal tracking-widest text-center text-black  border-2 text-sm border-gray-200 rounded h-20">
                {menus[9]}
              </div>
            </>
          )}
          <ButtomSocialMenu menuItemsCount={menuItemsCount} />
        </Menu.Items>
      </Menu>
    </>
  )
}

export default MobileMenu
